<template>
  <div class="col-lg-4 col-md-6">
    <a class="app d-block" rel="noopener noreferrer" target="_blank"
       v-bind:href="link">
      <b-card>
        <b-icon v-if="isMobileAgent" icon="cloud-arrow-down-fill" class="download"/>
        <div class="card-title img-content">
          <img
            :src="require(`@/assets/images/${role && role.image ?  role.image : app.image}`)"
            :alt="isMobileAgent ? $t(`app.${app.name}`) : $t(getRoleName(role))"
            class="w-100"
          />
        </div>
        <b-card-text class="d-flex flex-column">
          <div v-text="isMobileAgent ? $t(`app.${app.name}`) : $t(getRoleName(role))"/>
          <div class="role-description"
               v-if="role && role.description"
               v-text="$t(role.description)"/>
        </b-card-text>
      </b-card>
    </a>
  </div>
</template>

<script lang="js">
export default {
  props: ['app', 'role', 'isMobileAgent'],
  methods: {
    getRoleName(role) {
      return `role.${role.name}`;
    },
  },
  computed: {
    link() {
      const device = navigator.userAgent;

      if (this.isMobileAgent && (device.match(/Iphone/i)
        || device.match(/Ipod/i)
        || device.match(/iPhone|iPad|iPod/i))) {
        return this.app.iosApp;
      }
      if (this.isMobileAgent && device.match(/Android/i)) {
        return this.app.androidApp;
      }

      return this.role.path.startsWith('http') ? this.role.path : this.app.url + this.role.path;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/scss/variables";

  .app {
    padding: 7px;
    background-color: white;
    border-radius: 0.25rem;
    margin-bottom: 30px;

    &, & > .card {
      text-decoration: none;
    }

    &:hover .card .card-text {
      background-color: $dark-color;
      color: $prosegur-color;
    }

    & > .card {
      border: 0;
      background-color: $base-color;
      text-align: center;
      overflow: hidden;

      .card-text {
        min-height: 63px;
        padding: 0 1em;
        justify-content: center;
        background-color: $prosegur-color;
        color: $dark-color;
        font-weight: 500;
        font-size: 18px;
      }

      .card-body {
        padding: 0;

        .role-description{
          font-size: .9em;
        }

        .download{
          font-size: 2em;
          position: absolute;
          right: .4em;
          top: .2em;
          color: $dark-color;
        }

        .img-content {
          padding: 1.5em 0;
          margin: 0;

          img {
            height: 80px;
            width: 80px;
          }
        }
      }
    }
  }

</style>
