import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signedIn: false,
    user: {},
    apps: [],
    regions: [],
    region: '',
    regionLocked: false,
  },
  mutations: {
    signedIn_success(state, signedIn) {
      state.signedIn = signedIn;
    },
    userDetail_success(state, user) {
      state.user = user;
      state.region = localStorage.getItem('region') || user.country.region.name;
    },
    apps_success(state, apps) {
      state.apps = apps;
    },
    regions_success(state, regions) {
      state.regions = regions;
    },
    change_region(state, region) {
      state.region = region;
      localStorage.setItem('region', region);
    },
    lock_region(state, locked) {
      state.regionLocked = locked;
    },
  },
  actions: {
    init({ dispatch }) {
      const loader = Vue.prototype.$loading.show();
      return Promise.all([
        dispatch('signedIn'),
        dispatch('userDetail'),
        dispatch('loadRegions'),
      ]).then(async () => {
        if (this.state.user.admin || this.state.user.manager) {
          await dispatch('loadApplications');
        }
        return true;
      }).finally(() => {
        loader.hide();
      });
    },
    signedIn({ commit }) {
      return Vue.prototype.$auth.getSignedIn()
        .then((signedIn) => {
          commit('signedIn_success', signedIn);
        });
    },
    userDetail({ commit }) {
      return Vue.prototype.$api.getLoggedUserDetail()
        .then(async (user) => {
          commit('userDetail_success', user);
          return user;
        }).catch((error) => error);
    },
    loadApplications({ commit }) {
      return Vue.prototype.$api.getApplications()
        .then((apps) => {
          commit('apps_success', apps.content);
          return apps;
        });
    },
    loadRegions({ commit }) {
      return Vue.prototype.$api.getRegions()
        .then((regions) => {
          commit('regions_success', regions);
          return regions;
        });
    },
    changeRegion({ commit }, region) {
      commit('change_region', region);
    },
    lockRegion({ commit }) {
      commit('lock_region', true);
    },
    unlockRegion({ commit }) {
      commit('lock_region', false);
    },
  },
  getters: {
    isSignedIn: (state) => state.signedIn && state.user.id,
    user: (state) => state.user,
    isCorporate: (state) => state.user.corporate,
    isAdmin: (state) => state.user.admin,
    isManager: (state) => state.user.manager,
    hasAnyRolAdmin: (s, getters) => getters.isManager || getters.isAdmin,
    apps: (state) => state.apps,
    regions: (state) => state.regions,
    region: (state) => state.region,
    regionLocked: (state) => state.regionLocked,
  },
});
