<template>
  <main class="home absolute-container">
    <Header>
      <template slot="title">
        <div class="container-header">
          <b-row>
            <b-col md="12" class="pt-3 pb-3">
              <img src="@/assets/images/logoprosegurBlanco.svg" alt="PROSEGUR Cash" width="40"/>
            </b-col>
            <b-col md="12">
              <p class="title">
                {{ $t('home.welcome', [userinfo.name || '']) }}
              </p>
            </b-col>
          </b-row>
        </div>
      </template>
      <div class="container-header">
        <b-row class="pb-3">
          <b-col md="12" class="pb-5">
            <h1 class="apps-availables">{{ $t('home.appsAvailables') }}</h1>
          </b-col>
        </b-row>
      </div>
    </Header>
    <div class="applications container d-flex flex-column">
      <b-row>
        <template v-for="app in apps">
          <template v-if="isMobileAgent && (app.androidApp || app.iosApp)">
            <AppCard
              v-bind:key="app.name"
              :isMobileAgent="isMobileAgent"
              :app="app"/>
          </template>

          <template v-else>
            <template v-for="role in app.roles">
              <AppCard
                v-if="role.path"
                v-bind:key="role.id"
                :app="app"
                :role="role"/>
              <template v-else-if="role.roles && role.roles.length">
                <template v-for="role in role.roles">
                  <AppCard
                    v-if="role.path"
                    v-bind:key="role.id"
                    :app="app"
                    :role="role"/>
                </template>
              </template>
            </template>
          </template>
        </template>
        <div class="col-lg-12"
             v-if="!apps || apps.length === 0 ||
             !apps.find(app => hasAnyRoleAllowed(app.roles, !!(app.iosApp || app.androidApp)))">
          <b-card class>
            <b-card-text>
              <div class="d-flex align-items-center">
                <b-icon icon="exclamation-circle-fill" class=" mr-3 no-apps-icon "/>
                <div>
                  <span class="no-apps-available">{{ $t('home.noAppsTitle') }}</span>
                  <p>
                    <span>{{ $t('home.noAppsDesc1') }}</span>
                    <br>
                    <span>{{ $t('home.noAppsDesc2') }}</span>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-row>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import AppCard from '@/components/AppCard.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'home',
  components: {
    AppCard,
    Header,
  },
  data() {
    return {};
  },
  computed: {
    isMobileAgent() {
      const device = navigator.userAgent;

      return device.match(/Iphone/i) || device.match(/Ipod/i) || device.match(/iPhone|iPad|iPod/i) || device.match(/Android/i);
    },
    apps() {
      return this.$store.getters.user.apps.filter((app) => app.region.name === this.currentRegion || app.region.name === 'GLOBAL');
    },
    currentRegion() {
      return this.$store.getters.region;
    },
    userinfo() {
      return this.$store.getters.user;
    },
  },
  methods: {
    hasAnyRoleAllowed(roles, mobileVersion) {
      if (roles) {
        return roles.find((role) => {
          if (role.roles && role.roles.length) {
            return this.hasAnyRoleAllowed(role.roles, mobileVersion);
          }
          return !!(role.path || (this.isMobileAgent && mobileVersion));
        });
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .home {
    .apps-availables {
      font-size: 1.5rem;
    }

    .no-apps-available {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .applications {
      margin-top: -40px;
    }

    @media (min-width: 992px) {
      .container {
        padding: 0 150px;
      }
      .container-header {
        padding: 0 135px;
      }
    }

    .no-apps-icon {
      font-size: 42px
    }
  }
</style>
