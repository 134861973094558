<template>
  <main class="absolute-container">
    <Header :title="$t('page.userManagement')">
      <FilterBar :filters="filters" ref="filterBar"/>
    </Header>
    <div class="container d-flex flex-column">
      <b-table
        :fields="columns"
        :items="getUsers"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-direction="desc"
        :empty-text="$t('table.emptyText')"
        :busy.sync="isBusy"
        hover
        borderless
        ref="tableUsers"
        no-border-collapse
        stacked="md"
        show-empty
      >
        <template v-slot:table-busy>
          <div class="text-center loader my-2">
            <b-spinner class="align-middle"/>
          </div>
        </template>
        <template v-slot:cell(show_details)="row">
          <b-button @click="row.toggleDetails" block class="mr-2 details" size="sm"
                    variant="transparent" :title="$t('button.detail')" v-b-tooltip.hover>
            <b-icon :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
                    font-scale="0.9" shift-v="4"/>
          </b-button>
        </template>
        <template v-slot:cell(show_details_sm)="row">
          <b-button @click="row.toggleDetails" block class="mr-2" size="sm" variant="transparent">
            <b-icon :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
                    font-scale="0.9" shift-v="4"/>
          </b-button>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col>
                <b-form-checkbox class="custom-control-inline"
                                 :checked="row.item.manager"
                                 disabled>
                  {{ $t('user.manager') }}
                </b-form-checkbox>
                <b-form-checkbox class="custom-control-inline"
                                 v-if="isAdmin || row.item.admin"
                                 :checked="row.item.admin"
                                 disabled>
                  {{ $t('user.admin') }}
                </b-form-checkbox>
                <b-form-checkbox class="custom-control-inline" v-model="row.item.active"
                                 :value="true" :unchecked-value="false" disabled>
                  {{ $t('user.active') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col md="12">
                <b-row class="mb-2">
                  <b-col md="6">
                    <b-row>
                      <b-col class="text-sm-right" sm="5">
                        <strong>{{ $t('user.username') }}:</strong>
                      </b-col>
                      <b-col>{{ row.item.username }}</b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.firstName') }}:</strong></b-col>
                  <b-col>{{ row.item.name }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.lastName') }}:</strong></b-col>
                  <b-col>{{ row.item.surname }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.email') }}:</strong></b-col>
                  <b-col>{{ row.item.email }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.nif') }}:</strong></b-col>
                  <b-col>{{ row.item.nif }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.phoneNumber') }}:</strong>
                  </b-col>
                  <b-col>{{ row.item.phone }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.country') }}:</strong>
                  </b-col>
                  <b-col v-if="row.item.country && row.item.country.code">{{
                      $t('country.' +
                        row.item.country.code.toLowerCase())
                    }}
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.department') }}:</strong>
                  </b-col>
                  <b-col>{{ row.item.department }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.client') }}:</strong>
                  </b-col>
                  <b-col>{{ row.item.clientDescription }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.managerId') }}:</strong>
                  </b-col>
                  <b-col v-if="row.item.supervisor">{{ row.item.supervisor.username }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('user.emailManager') }}:</strong>
                  </b-col>
                  <b-col v-if="row.item.supervisor">{{ row.item.supervisor.email }}</b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row class="mb-2">
                  <b-col class="text-sm-right" sm="5">
                    <strong>{{ $t('header.permissions') }}:</strong>
                  </b-col>
                  <b-col>
                    <span class="commaSeparator" v-for="rol in translateRoles(row.item.apps)"
                          :key="rol">
                        {{ rol }}
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </template>
        <template v-slot:cell(actions)="row">
          <div class="table-button-container">
            <b-dropdown size="sm" right variant="outline-secondary"
                        toggle-class="text-decoration-none rounded-pill"
                        v-b-tooltip :title="$t('button.actions')" no-caret
                        v-if="isAdmin || row.item.id !== user.id">
              <template v-slot:button-content>
                <b-icon icon="three-dots" font-scale="0.9" shift-v="4"/>
              </template>
              <b-dropdown-item v-if="row.item.active" @click="editUser(row)" title>
                <b-icon icon="pencil-square"/>
                {{ $t('button.edit') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="!row.item.active" @click="enableUser(row)" title>
                <b-icon icon="person-check-fill"/>
                {{ $t('button.enable') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="row.item.active" @click="disableUser(row)" title>
                <b-icon icon="person-x-fill"/>
                {{ $t('button.disable') }}
              </b-dropdown-item>
              <template v-if="!row.item.corporate">
                <b-dropdown-item v-if="row.item.active" @click="resendWelcomeEmail(row)" title>
                  <b-icon icon="envelope"/>
                  {{ $t('button.resendActivationEmail') }}
                </b-dropdown-item>
              </template>
            </b-dropdown>&nbsp;&nbsp;
          </div>
        </template>

        <template v-slot:cell()="data">
            <span v-b-tooltip.lefttop :title="data.value">
                {{ data.value }}
            </span>
        </template>

        <template v-slot:cell(apps)="data">
            <span v-if="data.value && data.value.length > 1"
                  v-b-tooltip.lefttop
                  :title="data.value.join(', ')">
                {{ data.value[0] + ' ' + $t('global.moreOptions', [data.value.length - 1]) }}
            </span>
          <span v-else-if="data.value" v-b-tooltip.lefttop :title="data.value.join(', ')">
                {{ data.value[0] }}
            </span>
        </template>

      </b-table>
      <div>
        <div class="float-left pl-1 d-none d-md-block" v-if="items && !isBusy">
          <p>{{ 1 + (currentPage - 1) * perPage }} –
            {{ (currentPage - 1) * perPage + items }}
            {{ $t('global.of') }}
            {{ rows }}</p>
        </div>
        <b-pagination
          :per-page.sync="perPage"
          :total-rows="rows"
          @change="onPageChange"
          align="right"
          aria-controls="tableUsers"
          v-model="currentPage"
        >
          <template v-slot:first-text><span v-b-tooltip :title="$t('table.first')">«</span>
          </template>
          <template v-slot:next-text><span v-b-tooltip :title="$t('table.next')">›</span></template>
          <template v-slot:prev-text><span v-b-tooltip :title="$t('table.prev')">‹</span></template>
          <template v-slot:last-text><span v-b-tooltip :title="$t('table.last')">»</span></template>
        </b-pagination>
      </div>
    </div>
  </main>
</template>

<script lang="js">
import { mapGetters } from 'vuex';
import FilterBar from '@/components/FilterBar.vue';
import Header from '@/components/Header.vue';

export default {
  components: {
    FilterBar,
    Header,
  },
  data() {
    const { isAdmin } = this.$store.getters;
    const { isCorporate } = this.$store.getters;
    const params = this.$route.query;
    return {
      isBusy: false,
      perPage: params.size && Number(params.size) >= 10 ? params.size : 10,
      currentPage: params.page || 1,
      sortBy: params['sort.field'] || 'id',
      sortDesc: params['sort.order'] ? params['sort.order'] === 'DESC' : true,
      rows: 0,
      items: 0,
      filters: {
        corporate: params.corporate,
        admin: params.admin,
        manager: params.manager,
        username: params.username,
        name: params.name,
        surname: params.surname,
        email: params.email,
        country: isAdmin ? params.country : null,
        client: isAdmin || isCorporate ? params.client : null,
        department: params.department,
        region: this.$store.getters.region,
        supervisor: params.supervisor,
        active: params.active,
        roles: params.roles ? params.roles.split(',').map((r) => Number(r)).filter((r) => r) : null,
      },
    };
  },
  computed: {
    ...mapGetters(['region', 'isCorporate', 'isAdmin', 'user']),
    columns() {
      return [
        {
          key: 'show_details',
          label: '',
          sortable: false,
          class: 'd-none d-md-table-cell details',
        },
        {
          key: 'username',
          label: this.$t('header.nickname'),
          class: 'cell-ellipsis',
          sortable: true,
        },
        {
          key: 'name,surname',
          label: this.$t('header.fullName'),
          class: 'cell-ellipsis',
          sortable: true,
          formatter: (value, key, item) => `${item.name} ${item.surname}`.trim(),
        },
        {
          key: 'clientDescription',
          label: this.$t('user.client'),
          class: 'cell-ellipsis',
          sortable: true,
        },
        {
          key: 'apps',
          label: this.$t('header.permissions'),
          sortable: false,
          class: 'ellipsis',
          formatter: (value) => this.translateRoles(value),
        },
        {
          key: 'active',
          label: this.$t('header.status'),
          sortable: false,
          formatter: (value) => (value ? this.$t('user.active') : this.$t('user.inactive')),
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          class: 'd-block d-md-table-cell actions',
        },
        {
          key: 'show_details_sm',
          label: '',
          sortable: false,
          class: 'd-block d-md-none text-center',
        },
      ];
    },
  },
  beforeCreate() {
    if (!this.$store.getters.isAdmin) {
      this.$store.dispatch('changeRegion', this.$store.getters.user.country.region.name);
    }
  },
  mounted() {
    if (!this.$store.getters.isAdmin) {
      this.$store.dispatch('lockRegion');
      this.$store.dispatch('changeRegion', this.$store.getters.user.country.region.name);
    }

    this.$events.$on('filter-set', (eventData) => this.onFilterSet(eventData));
    this.$events.$on('filter-reset', () => this.onFilterReset());
    this.$events.$on('export', (eventData) => this.onExport(eventData));
    this.$events.$on('refresh', () => this.onRefresh());
    this.$events.$on('region-change', (e) => this.onRegionChange(e));
  },
  beforeDestroy() {
    this.$store.dispatch('unlockRegion');
    this.$events.off('filter-set');
    this.$events.off('filter-reset');
    this.$events.off('export');
    this.$events.off('refresh');
    this.$events.off('region-change');
  },
  methods: {
    resetFilters() {
      const userAddress = this.user.address;
      const userCountry = userAddress && userAddress.country
        ? userAddress.country.toLowerCase() : null;

      Object.keys(this.filters).forEach((key) => {
        switch (key) {
          case 'client':
            this.filters.client = this.isAdmin || this.isCorporate ? null : this.user.client;
            break;
          case 'country':
            this.filters.country = this.isAdmin ? null : userCountry;
            break;
          case 'region':
            this.filters.region = this.region;
            break;
          default:
            this.filters[key] = null;
        }
      });
    },
    getUsers() {
      const pageable = {
        size: this.perPage,
        page: this.currentPage,
        orderBy: this.sortBy || 'id',
        direction: this.sortDesc ? 'DESC' : 'ASC',
      };

      const apiFilters = this.createFilters(pageable, this.filters);

      this.$router.replace({ path: this.$route.path, query: apiFilters }).catch(() => {
        // do nothing
      });

      this.rows = 0;
      this.items = 0;
      apiFilters.page = Number(apiFilters.page) - 1;

      return this.$api.getUsers(apiFilters).then((response) => {
        this.rows = response.totalElements;
        this.items = response.content.length;

        // Fix error numero de la paginacion
        this.$nextTick(() => {
          this.currentPage = this.$route.query.page || 1;
        });

        return response.content;
      }).catch(() => []);
    },
    onPageChange() {
      this.$refs.tableUsers.refresh();
    },
    onRefresh() {
      this.$refs.tableUsers.refresh();
    },
    onRegionChange(e) {
      this.filters.country = '';
      this.filters.region = e;
      this.$refs.tableUsers.refresh();
    },
    onFilterSet() {
      if (this.$api.getUsers.isCancellable()) {
        this.isBusy = false;
        this.$api.getUsers.cancel();
      }
      this.currentPage = 1;
      this.$refs.tableUsers.refresh();
    },
    onFilterReset() {
      if (this.$api.getUsers.isCancellable()) {
        this.isBusy = false;
        this.$api.getUsers.cancel();
      }
      this.currentPage = 1;
      this.resetFilters();
      this.$refs.tableUsers.refresh();
    },
    onExport() {
      this.$swal({
        title: this.$t('export.confirmTitle'),
        html: this.$t('export.confirmText'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      }).then((result) => {
        if (result.value) {
          this.exportUsers();
        }
      });
    },
    exportUsers() {
      this.$api.generateReportUsers(this.createFilters({}, this.filters)).then(() => {
        this.$swal({
          title: this.$t('export.successTitle'),
          text: this.$t('export.successText'),
          type: 'success',
        });
      });
    },
    editUser(row) {
      this.$router.push({ name: 'user', params: { id: row.item.id, user: row.item } });
    },
    resendWelcomeEmail(row) {
      this.$swal({
        title: this.$t('resendActivationEmail.confirmTitle'),
        html: this.$t('resendActivationEmail.confirmText'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      }).then((result) => {
        if (result.value) {
          this.$api.resendWelcomeEmail(row.item.id, true).then(() => {
            this.$swal({
              title: this.$t('resendActivationEmail.successTitle'),
              text: this.$t('resendActivationEmail.successText'),
              type: 'success',
            });
          })
            .catch((error) => {
              if (error.response.status === 429) {
                this.$swal({
                  title: this.$t('resendActivationEmail.tooManyRequestsTitle'),
                  text: this.$t('resendActivationEmail.tooManyRequestsText'),
                  type: 'error',
                });
              } else {
                this.unexpectedError();
              }
            });
        }
      });
    },
    enableUser(row) {
      this.$swal({
        title: this.$t('enableUser.confirmTitle'),
        html: this.$t('enableUser.confirmText'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      }).then((result) => {
        if (result.value) {
          this.$api.changeStatusUser(row.item.id, true).then(() => {
            this.$swal({
              title: this.$t('enableUser.successTitle'),
              text: this.$t('enableUser.successText'),
              type: 'success',
            });

            row.item.active = true;
          })
            .catch(() => this.unexpectedError());
        }
      });
    },
    disableUser(row) {
      this.$swal({
        title: this.$t('disableUser.confirmTitle'),
        html: this.$t('disableUser.confirmText'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      }).then((result) => {
        if (result.value) {
          this.$api.changeStatusUser(row.item.id, false).then(() => {
            this.$swal({
              title: this.$t('disableUser.successTitle'),
              text: this.$t('disableUser.successText'),
              type: 'success',
            });

            row.item.active = false;
          })
            .catch(() => this.unexpectedError());
        }
      });
    },
    unexpectedError() {
      this.$swal({
        title: this.$t('unexpectedError.title'),
        text: this.$t('unexpectedError.description'),
        type: 'error',
      });
    },

    createFilters(pageable, filters, prev) {
      let result = pageable;

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          if (filters[key] && typeof filters[key] !== 'object') {
            result[(prev ? `${prev}.` : '') + key] = filters[key];
          } else if (Array.isArray(filters[key]) && filters[key].length > 0) {
            result[(prev ? `${prev}.` : '') + key] = filters[key].join(',');
          } else if (typeof filters[key] === 'object') {
            result = this.createFilters(result, filters[key], key);
          }
        }
      });

      return result;
    },
    translateRoles(apps) {
      const rolesTranslated = [];
      if (apps) {
        apps.forEach((app) => {
          app.roles.forEach((role) => rolesTranslated.push(...this.translateRole(role)));
        });
      }
      return rolesTranslated.sort();
    },
    translateRole(role) {
      return role.roles && role.roles.length ? role.roles.map((r) => (this.translateRole(r))).flat() : [this.$t(`role.${role.name}`)];
    },
  },
};
</script>

<style lang="scss">

.ellipsis span {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.commaSeparator:not(:first-child)::before {
  content: "\A";
  white-space: pre;
}

.table.b-table.b-table-no-border-collapse {
  border-spacing: 0 4px !important;
}

.b-table > tbody > tr {
  margin: 15px 0;

  &:not(.table-danger) td {
    background-color: white;
  }

  & > td:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  & > td.actions {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;

    .dropdown-item {
      padding-left: 1rem;

      i, em, svg {
        margin-right: .3rem;
        padding-right: .2rem;
      }
    }
  }

  & > td.details {
    overflow: visible;
  }

  @media (max-width: 767px) {
    & > td:nth-child(2) {
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
    }
    & > td:last-child {
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top: 1px solid #9ea4a9;
    }
  }
}

@media (max-width: 767px) {
  .table tr td:first-child::before, .table tr td:last-child::before {
    display: none !important;
  }

  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

.pagination .page-item .page-link {
  padding: .75rem 1rem;

  & > span {
    margin: -.75rem -1rem;
    padding: .75rem 1rem;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .cell-ellipsis {
    position: relative;
    min-width: 220px;

    div {
      display: inline;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      padding: .5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:before {
      content: '&nbsp;';
      visibility: hidden;
    }
  }
}

</style>
