<template>
  <div class="container-navbar">
    <b-navbar id="menu" toggleable="lg" type="dark" sticky>
      <b-navbar-brand :to="{name: 'home'}" class="py-0">
        <img src="@/assets/images/logoProsegurCashFullBlanco.svg" alt="PROSGUR Cash" height="55"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"/>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'home'}">{{ $t("navbar.home") }}</b-nav-item>
          <b-nav-item v-if="hasAnyRolAdmin" :to="{ name: 'admin'}">
            {{ $t("navbar.admin") }}
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            id="dropdownRegion"
            class="btn mr-lg-1"
            v-if="regions && regions.length > 1 && !regionLocked"
            right
          >
            <template slot="button-content">
              <b-icon icon="geo-alt-fill" font-scale="1.1"/>
              <span v-text="currentRegion"/>
            </template>
            <b-dropdown-item
              v-for="(region) in regions"
              v-bind:key="region"
              @click="changeRegion(region)"
              v-bind:active="region === currentRegion"
            >
              <span v-text="region"/>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item class="dropdown mr-lg-1" v-else>
            <b-icon icon="geo-alt-fill" font-scale="1.1"/>
            <span v-text="currentRegion"/>
          </b-nav-item>

          <b-nav-item-dropdown id="dropdownLanguage" class="btn mr-lg-1" right>
            <template slot="button-content">
              <b-icon icon="globe" font-scale="1.1"/>
              <span>{{ $t("language." + currentLang) }}</span>
            </template>
            <b-dropdown-item
              v-for="(lang, index) in langs"
              v-bind:key="index"
              @click="changeLanguage(lang)"
              v-bind:active="lang === currentLang"
            >
              <span>{{ $t("language." + lang) }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown id="dropdownUser" class="btn" right no-caret>
            <template slot="button-content">
              <b-icon icon="person-circle"/>
              <div>
                <div v-text="(userInfo.name || '') + ' ' + (userInfo.surname || '')"></div>
                <div class="link-prosegur" v-text="userInfo.username "></div>
              </div>
            </template>

            <b-dropdown-item :to="{ name: 'profile' }">
              <b-icon icon="person-circle"/>
              <span class="pl-3">{{ $t("navbar.profile") }}</span>
            </b-dropdown-item>

            <b-dropdown-item target="_blank" :to="{ path: '/callback?type=CHANGE_PWD'}"
                             v-if="!isCorporate">
              <b-icon icon="key-fill" />
              <span class="pl-3">{{ $t("user.changePassword") }}</span>
            </b-dropdown-item>

            <b-dropdown-item target="_blank" :to="{ path: '/callback?type=RESET_MFA'}"
                             v-if="!isCorporate">
              <icon2-f-a />
              <span class="pl-3">{{ $t("user.resetMFA") }}</span>
            </b-dropdown-item>

            <b-dropdown-divider/>
            <b-dropdown-item @click="logout">
              <b-icon icon="power" />
              <span class="pl-3">{{ $t("navbar.logout") }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon2FA from '@/components/Icon2FA.vue';

export default {
  data() {
    return {
      currentLang: this.$i18n.locale,
      langs: ['de', 'es', 'en', 'pt'],
    };
  },
  components: {
    Icon2FA,
  },
  computed: {
    ...mapGetters(['user', 'isCorporate', 'hasAnyRolAdmin', 'regionLocked']),

    currentRegion() {
      return this.$store.getters.region;
    },
    userInfo() {
      if (this.user.country
        && this.user.country.region
        && this.regions.length
        && this.regions.length < 2) {
        this.changeRegion(this.user.country.region.name);
      }

      return this.user;
    },
    regions() {
      if (this.user.admin) {
        return this.$store.getters.regions
          .filter((region) => region.name !== 'GLOBAL')
          .map((region) => region.name);
      }

      return this.user.apps.map((app) => app.region.name).reduce((acc, item) => {
        if (item !== 'GLOBAL' && !acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, [this.user.country.region.name]);
    },
  },
  methods: {
    changeRegion(newRegion) {
      this.$store.dispatch('changeRegion', newRegion);
      this.$events.fire('region-change', newRegion);
    },
    changeLanguage(newLanguage) {
      document.cookie = `LOCALE=${newLanguage}; path=/`;
      this.currentLang = newLanguage;
      this.$events.fire('language-change', newLanguage);
    },
    logout() {
      this.$swal({
        title: this.$t('navbar.logout'),
        text: this.$t('navbar.confirmLogout'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      }).then((result) => {
        if (result.value) {
          this.$auth.signOut();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import '../assets/scss/style';

.container-navbar {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
}

#menu::v-deep {
  min-height: 65px;
  background-color: rgba(0, 0, 0, 0.6);

  & .navbar-nav .nav-item a.nav-link {
    &, &:hover, &:active, &:focus {
      color: white;
    }

    &.router-link-active {
      color: $prosegur-color;
    }
  }

  .dropdown-toggle::after {
    color: $prosegur-color;
  }

  .dropdown-menu-right::before {
    right: 14px;
  }

  .dropdown-menu-right::after {
    right: 15px;
  }

  .dropdown-menu-left::before {
    left: 14px;
  }

  .dropdown-menu-left::after {
    left: 15px;
  }

  #dropdownUser {
    i, em, svg {
      margin: auto 1rem auto 0;
      line-height: 2;
    }

    & > .nav-link {
      display: flex;

      i, em, svg {
        font-size: 1.5em;
      }

      .link-prosegur {
        color: $prosegur-color !important;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;

      i, em, svg {
        font-size: 2em;
        line-height: 1.2;
      }

      padding: 0.8rem 1.5rem;
    }
  }
}

@media (min-width: 992px) {
  #menu::v-deep {
    padding: 0 1rem !important;

    .navbar-nav .nav-item {
      position: relative;
    }

    .navbar-nav .nav-item.btn {
      padding: 0;
      border: 0;
    }

    .navbar-nav .nav-item a.nav-link {
      padding: 1.3rem;
    }

    .navbar-nav .nav-item a.nav-link > span, .navbar-nav .nav-item .dropdown-item > span {
      padding-left: 4px;
    }

    .navbar-nav:not(.ml-auto) .nav-item a:after {
      background: $prosegur-color;
      bottom: -0.1rem;
      content: "";
      display: none;
      height: 5px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .navbar-nav:not(.ml-auto) .nav-item:hover a:after {
      display: inherit;
    }

    #dropdownUser {
      display: flex;
      border-left: 1px solid rgba($base-color, .5);
      border-radius: 0;
      text-align: left;

      & > .nav-link {
        min-width: 200px;
        padding: .55rem 1.5rem;
      }

      .dropdown-item {
        min-width: 220px;
      }
    }

    .dropdown-menu::before {
      position: absolute;
      top: -7px;
      display: inline-block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #ccc;
      border-left: 7px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: "";
    }

    .dropdown-menu::after {
      position: absolute;
      top: -6px;
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      border-left: 6px solid transparent;
      content: "";
    }

    .dropdown-menu.dropdown-menu-right::before {
      right: 6px;
    }

    .dropdown-menu.dropdown-menu-right::after {
      right: 7px;
    }
  }
}

@media (max-width: 991px) {
  #menu::v-deep {
    .navbar-nav:not(.ml-auto) .nav-item,
    .dropdown:not(:last-child) {
      border-bottom: 1px solid #6d7073;
    }

    .navbar-nav .nav-item.btn {
      text-align: left;
      padding: 0;
      border-radius: 0;
    }
  }

  .container-navbar {
    background-image: url($url-background);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    #menu {
      background-color: rgba(0, 0, 0, .8);
    }
  }
}
</style>
