import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Profile from './views/Profile.vue';
import Admin from './views/Admin.vue';
import Users from './views/Users.vue';
import NewUser from './views/NewUser.vue';
import EditUser from './views/EditUser.vue';
import Error404 from './views/404.vue';
import GenericError from './views/Error.vue';
import Callback from './views/Callback.vue';

Vue.use(Router);

const vueRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'home' },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        manager: true,
      },
      redirect: { name: 'admin-users' },
      children: [{
        path: 'users',
        name: 'admin-users',
        component: Users,
      },
      {
        path: 'newuser',
        name: 'newuser',
        component: NewUser,
      },
      {
        path: 'users/:id',
        name: 'user',
        component: EditUser,
      }],
    },
    {
      path: '/callback',
      name: 'callback',
      component: Callback,
      meta: {
        anonymous: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: Error404,
    }, {
      path: '/error',
      name: 'error',
      component: GenericError,
    }, {
      path: '*',
      redirect: '/404',
    },
  ],
});

const router = () => {
  let isInit;
  vueRouter.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.anonymous)) {
      next();
    } else {
      localStorage.setItem('page.current', `${window.origin}${to.fullPath}`);

      if (!isInit) {
        isInit = await store.dispatch('init');
      }

      if (to.matched.some((record) => record.meta.manager)) {
        if (store.getters.hasAnyRolAdmin) {
          next();
        } else {
          next({
            name: 'error',
            query: {
              type: 403,
            },
          });
        }
      } else {
        next();
      }
    }
  });

  return vueRouter;
};

export default router;
