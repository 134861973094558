<template>
  <main class="absolute-container">
    <Header>
      <div class="error-container col-md-6 m-auto">
        <div class="error-main">
          <div class="error-number" v-if="error.number !== 'unexpectedError.number'">
            {{ $t(error.number) }}
          </div>
          <div class="error-title pb-3" v-else>{{ $t(error.title) }}</div>
        </div>
      </div>
    </Header>
    <div class="container d-flex flex-column">
      <div class="error-detail col-md-6 m-auto">
        <div class="error-main">
          <div class="error-title" v-if="error.number !== 'unexpectedError.number'">
            {{ $t(error.title) }}
          </div>
          <div class="error-description">{{ $t(error.desc) }}</div>
          <br/>
          <router-link :to="{name: 'home'}">
            <span class="volver-caret">
              <span class="caret-left"/>
            </span>
            <span class="volver-error">{{ $t("button.backHome") }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="js">
import Header from '@/components/Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      type: this.$route.query.type || '',
    };
  },
  computed: {
    error() {
      switch (this.type.toString()) {
        case '401':
        case '403':
          return {
            number: 'error403.number',
            title: 'error403.title',
            desc: 'error403.description',
          };
        case '500':
          return {
            number: 'error500.number',
            title: 'error500.title',
            desc: 'error500.description',
          };
        default:
          return {
            number: 'unexpectedError.number',
            title: 'unexpectedError.title',
            desc: 'unexpectedError.description',
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/scss/variables";

  .error-main {
    text-align: center;
  }

  .error-title {
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 1rem;

    @media (max-width: 767px) {
      & {
        font-size: 30px;
      }
    }
  }

  .error-description {
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 767px) {
      & {
        font-size: 1rem;
      }
    }
  }

  .error-number {
    font-size: 80px;
    font-weight: 300;
    line-height: 130px;

    @media (max-width: 767px) {
      & {
        font-size: 60px;
      }
    }

  }

  .error-detail {
    color: $dark-color;

    .error-main {
      margin-top: 10%;
    }
  }

  .volver-caret {
    background-color: $prosegur-color;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    color: $dark-color;
    font-size: 16px;
    display: inline-flex;
    vertical-align: top;
  }

  .caret-left {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-right: 5px solid;
    display: inline-block;
    height: 0;
    width: 0;
    margin: auto auto auto 5px;
  }

  .volver-error {
    display: inline-block;
    color: $dark-color;
    font-size: 1em;
    line-height: 1em;
    vertical-align: top;
  }

  a,
  a:active,
  a:focus,
  a:hover {
    outline: 0;
    text-decoration: none;
  }
</style>
