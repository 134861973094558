<template>
  <p-modal
    :title="$t('modal.findClient.title')"
    size="md"
    ref="findClientByCountry"
    modal-id="findClientByCountry"
    @shown="initFields"
    @close="onClose"
    @hide="onClose"
  >
    <template #modal-content>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onAccept)">
          <b-row>
            <b-col>
              <ValidationProvider rules="required" v-slot="{ valid, errors }" mode="passive">
                <b-form-group :label="$t('user.sourceSystem')">
                  <KeyMultiselect
                    id="sourceSystemSelected"
                    name="sourceSystemSelected"
                    v-model="sourceSystem"
                    :options="asignableSourceSystem"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="20"
                    :show-no-results="false"
                    :show-labels="false"
                    :limit-text="limitText"
                    track-by="id"
                    :placeholder="$t('global.selectOptionOrTyping')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    aria-describedby="aria-source-system-modal"
                    autocomplete="off"
                  >
                    <template v-slot:option="props">
                      <span v-text="props.option.description"/>
                    </template>
                    <template v-slot:singleLabel="props">
                      <span v-text="props.option.description"/>
                    </template>
                  </KeyMultiselect>
                  <b-form-invalid-feedback id="aria-source-system-modal">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ValidationProvider rules="required|client_exists"
                                  v-slot="{ errors, validate }"
                                  mode="passive"
              >
                <b-form-group :label="$t('user.clientCode')">
                  <b-input-group>
                    <b-form-input
                      id="ipt_client_modal"
                      name="ipt_client_modal"
                      v-model="clientCode"
                      autocomplete="off"
                      aria-describedby="aria-client-modal"
                      :state="errors[0] ? false : null"
                      @change="onChangeClientCode"
                    />
                    <b-input-group-append>
                      <b-button
                        id="btn_find_client"
                        name="btn_find_client"
                        variant="outline-secondary"
                        :disabled="!clientCode"
                        @click="validate()">
                        <b-icon icon="search" font-scale="0.9" shift-v="2"/>
                      </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="aria-client-modal">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ValidationProvider rules="required" v-slot="{ valid, errors }" mode="passive">
                <b-form-group :label="$t('user.description')">
                  <b-form-input
                    id="ipt_client_description"
                    name="ipt_client_description"
                    v-model="clientDescription"
                    :readonly="true"
                    aria-describedby="client_description-modal"
                    :state="errors[0] ? false : (valid ? true : null)"
                  />
                  <b-form-invalid-feedback id="client_description-modal">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="text-right">
              <div class="btn_accept">
                <b-button variant="primary" type="submit">
                  {{ $t('button.accept') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </template>
  </p-modal>
</template>

<script>
import { extend, ValidationObserver } from 'vee-validate';
import PModal from '@/components/PModal.vue';
import KeyMultiselect from './KeyMultiselect.vue';

export default {
  name: 'find-client-modal',
  components: {
    PModal,
    ValidationObserver,
    KeyMultiselect,
  },
  data() {
    return {
      sourceSystem: '',
      genesisCode: '',
      clientCode: '',
      clientDescription: '',
      asignableSourceSystem: [
        { id: 'SOL', code: 'SOL', description: 'SOL' },
        { id: 'GENESIS', code: 'GENESIS', description: 'GENESIS' },
      ],
      isLoading: false,
    };
  },
  created() {
    extend('client_exists', {
      validate: () => this.onLoadData(),
      message: () => this.$t('errorValidator.client_exists'),
    });
  },
  props: ['form'],
  methods: {
    initFields() {
      this.genesisCode = this.form.genesisCode;
      this.sourceSystem = 'SOL';
      this.clientCode = this.form.clientCode;
      this.clientDescription = this.form.clientDescription;
    },
    onLoadData() {
      return this.$api.getSolClient(this.form.countryCode, this.clientCode, this.sourceSystem)
        .then((response) => {
          this.genesisCode = response.codeGenesis;
          this.clientDescription = response.descriptionName;
          return true;
        })
        .catch((error) => {
          console.log(error.response.data);
          return false;
        });
    },
    onAccept() {
      this.form.sourceSystem = this.sourceSystem;
      this.form.genesisCode = this.genesisCode;
      this.form.clientCode = this.clientCode;
      this.form.clientDescription = this.clientDescription;
      this.$bvModal.hide('findClientByCountry');
      this.$emit('change', this.clientDescription);
    },
    onClose() {
      this.genesisCode = '';
      this.sourceSystem = '';
      this.clientCode = '';
      this.clientDescription = '';
    },
    onChangeClientCode() {
      this.genesisCode = '';
      this.clientDescription = '';
    },
  },
};

</script>

<style lang="scss">
.btn_accept {
  margin-top: 10px;
}
</style>
