<template>
  <p-modal
    :title="$t('sessionIdle.title')"
    size="s"
    ref="sessionExpiredModal"
    modal-id="sessionExpiredModal"
  >
    <template #modal-content>
      <b-row class="mt-3 text-center">
        <b-col>
          <span class="font-weight-bold">{{ $t("sessionIdle.description") }}</span>
        </b-col>
      </b-row>
      <b-row class="mt-3 mb-3 text-center">
        <b-col>
          <span class="time">{{ timerCount }}</span>
        </b-col>
      </b-row>
    </template>
  </p-modal>
</template>

<script>
import AppIdle from '@app-idle/core';
import * as workerTimers from 'worker-timers';
import PModal from '@/components/PModal.vue';

export default {
  name: 'session-expired-modal',
  components: {
    PModal,
  },
  data() {
    return {
      appIdle: new AppIdle({
        timeout: 1800000,
        onIdle: () => this.onIdle(),
        onCancel: () => this.onCancel(),
        events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'wheel'],
        multi: true,
        storageKey: 'AppIdle',
        eventTarget: window,
      }),
      timeOutId: null,
      timerCount: 60,
    };
  },

  mounted() {
    this.appIdle.start();
  },

  methods: {
    onIdle() {
      this.$bvModal.show('sessionExpiredModal');

      this.timeOutId = workerTimers.setInterval(() => {
        this.timerCount -= 1;
        if (this.timerCount === 0) this.$auth.signOut();
      }, 1000);
    },
    onCancel() {
      if (this.timeOutId) {
        workerTimers.clearInterval(this.timeOutId);
        this.timeOutId = null;
        this.timerCount = 60;
        this.$bvModal.hide('sessionExpiredModal');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.message {
  font-size: 25px;
}

.time {
  font-size: 50px;
  color: $prosegur-color;
}
</style>
