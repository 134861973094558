<template>
  <main class="absolute-container">
    <ValidationObserver ref="observer" v-slot="{ validated, valid }">
      <b-form @submit.prevent="onSubmit" ref="observer">
        <Header :title="$t('page.editUser')">
          <b-row>
            <b-col xs="12" class="tabsTypeUser">
              <div class="w-100">
                <b-form-group class="mb-0">
                  <b-form-radio-group
                    id="radio-slots"
                    name="radio-options-slots"
                    buttons
                    button-variant="outline_secondary"
                    v-model="form.corporate"
                  >
                    <b-form-radio v-if="!form.corporate" :value="false">
                      {{ $t('user.clientUser') }}
                    </b-form-radio>
                    <b-form-radio v-if="form.corporate" :value="true">
                      {{ $t('user.prosegurUser') }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <div class="float-right">
                    <b-button-group class="table-controls">
                      <b-button :to="{ name: 'newuser' }"
                                class="d-flex"
                                variant="light"
                                v-if="showCreateAnotherUserButton"
                                v-b-tooltip.hover
                                :title="$t('button.newAnotherUser')">
                                <div class="d-flex align-items-center">
                                  <b-icon icon="person-plus-fill" font-scale="0.99" class="mr-2" />
                                  {{ $t('button.newAnotherUser') }}
                                </div>
                      </b-button>
                    </b-button-group>
                  </div>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </Header>
        <div class="container d-flex flex-column pt-4">
          <div class="justify-content-center mb-5">
            <b-col xs="12" class="contentTabsTypeUser">
              <b-row>
                <b-col xs="12">
                  <b-form-group class="mb-0">
                    <b-form-checkbox class="custom-control-inline"
                                     v-model="form.manager"
                                     :value="true" :unchecked-value="false" switch>
                      {{ $t('user.manager') }}
                    </b-form-checkbox>
                    <b-form-checkbox class="custom-control-inline"
                                     v-model="form.admin"
                                     :value="true" :unchecked-value="false"
                                     v-if="form.corporate && isAdmin" switch>
                      {{ $t('user.admin') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr/>
              <UserFields :edit="true"
                          :form="form"
                          :initialManager="initialManager"
                          :countries="countries"
                          :supervisor="supervisor"
                          :valid="valid"
                          :validated="validated"
                          v-if="form.username"
              />
            </b-col>

            <template v-for="(reg, index) in appsByRegion">
              <div class="card mb-2"
                   v-if="regionHasAvailableApps(reg) && regionHasAllowedApps(reg)"
                   :key="index">
                <div class="card-header" v-b-toggle="'collapse-' + reg.name">
                  <b-icon icon="chevron-down" class="float-right icon-card" font-scale="0.99"/>
                  <span v-text="$t('user.apps') + ' - ' +  reg.name"/>
                </div>
                <b-collapse :visible="isVisible(reg.name)" :id="'collapse-' + reg.name">
                  <div class="card-body">
                    <div class="row">
                      <template v-for="(app) in reg.apps">
                        <AppConfiguration
                          :key="'a-'+app.name"
                          v-if="app.active && appHasAllowedRoles(app)"
                          :userId="userId"
                          :isUserCorporate="form.corporate"
                          :titleapp="app.name"
                          :app="app"
                          :value="apps.find(userApp => userApp.id === app.id) || {}"
                          :region="reg.name"
                          :configurationFunction="openConfigurationPage"
                        />
                      </template>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </template>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </main>
</template>

<script lang="js">

import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Header from '@/components/Header.vue';
import UserFields from '@/components/UserFields.vue';
import AppConfiguration from '@/components/AppConfiguration.vue';

export default {
  components: {
    ValidationObserver,
    Header,
    UserFields,
    AppConfiguration,
  },
  data() {
    return {
      form: {
        admin: false,
        manager: false,
        username: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        countryCode: '',
        nif: '',
        department: '',
        supervisorId: '',
        genesisCode: '',
        clientCode: '',
        clientDescription: '',
      },
      supervisor: {
        id: '',
        username: '',
        email: '',
      },
      initialManager: null,
      userId: '',
      apps: [],
      showCreateAnotherUserButton: false,
    };
  },

  created() {
    const userData = this.$route.params.user;
    this.userId = this.$route.params.id;
    this.showCreateAnotherUserButton = this.$route.params.showCreateAnotherUserButton;
    if (userData) {
      this.loadUserInfo(userData);
    } else {
      this.getUser();
    }
  },

  beforeDestroy() {
    this.$store.dispatch('unlockRegion');
  },

  mounted() {
    this.$store.dispatch('lockRegion');
  },

  methods: {
    getUser() {
      this.$api.getUserDetail(this.$route.params.id).then((response) => {
        if (response) {
          if (!this.isAdmin && this.user.id === response.id) {
            this.$router.push({ name: 'error', query: { type: '401' } });
          } else {
            if (response.country.region.name !== this.region) {
              this.$store.dispatch('changeRegion', response.country.region.name);
            }

            this.loadUserInfo(response);

            requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
          }
        }
      })
        .catch((e) => {
          if (e.response.status === 401) {
            this.$router.push({ name: 'error', query: { type: '401' } });
          } else {
            this.$swal({
              title: this.$t('global.error'),
              html: this.$t('editUser.userNotExists'),
              type: 'error',
              confirmButtonText: this.$t('button.ok'),
            }).then(() => {
              this.$router.push({
                name: 'admin',
              });
            });
          }
        });
    },

    loadUserInfo(user) {
      this.form.admin = user.admin;
      this.form.manager = user.manager;
      this.form.sub = user.sub;
      this.form.username = user.username;
      this.form.name = user.name;
      this.form.surname = user.surname;
      this.form.email = user.email;
      this.form.phone = user.phone;
      this.form.countryCode = user.country.code.toLowerCase();
      this.form.nif = user.nif;
      this.form.department = user.department;
      this.form.corporate = user.corporate;
      this.form.genesisCode = user.genesisCode;
      this.form.clientCode = user.clientCode;
      this.form.clientDescription = user.clientDescription;
      this.form.supervisorId = user.supervisor ? user.supervisor.id : '';

      if (user.supervisor) {
        this.form.supervisorId = user.supervisor.id;

        this.supervisor.id = user.supervisor.id;
        this.supervisor.email = user.supervisor.email;
        this.supervisor.username = user.supervisor.username;

        this.initialManager = {
          ...this.initialManager,
          id: this.supervisor.id,
          username: this.supervisor.username,
          email: this.supervisor.email,
        };
      }

      this.apps = user.apps || [];
    },

    async onSubmit() {
      this.$events.fire('onSubmit');

      await this.$nextTick();

      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$swal({
          title: this.$t('editUser.confirmTitle'),
          html: this.$t('editUser.confirmText'),
          type: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
        }).then((result) => {
          if (result.value) {
            this.updateUser();
          }
        });
      });
    },

    updateUser() {
      this.$api.updateUser(this.form, this.userId).then(async () => {
        const promises = [];

        await Promise.all(promises);

        this.$swal({
          title: this.$t('editUser.successTitle'),
          html: this.$t('editUser.successText'),
          type: 'success',
          confirmButtonText: this.$t('button.ok'),
        });
      });
    },

    openConfigurationPage(app) {
      let url = `${app.url + app.configPath}`;
      url = url.replace('{username}', encodeURIComponent(this.form.username));
      url = url.replace('{sub}', this.form.sub);

      const win = window.open(url, '_blank');
      win.focus();
    },
    appHasAllowedRoles(app) {
      return app.roles
        .some((role) => (this.form.corporate ? role.activeCorporate : role.activeClient))
        && (this.isAdmin
        || this.apps.find((userApp) => userApp.id === app.id)
        || this.user.apps.find((userApp) => userApp.id === app.id));
    },
    regionHasAvailableApps(region) {
      return region.apps.some((app) => app.active && this.appHasAllowedRoles(app));
    },
    regionHasAllowedApps(region) {
      return this.isAdmin || region.apps.some((app) => this.appHasAllowedRoles(app));
    },
    isVisible(region) {
      return region === 'GLOBAL' ? true : this.region === region;
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'user', 'region']),

    appsByRegion() {
      const { regions, apps } = this.$store.getters;
      regions.forEach((region) => {
        region.apps = apps.filter((app) => app.region.name === region.name);
      });

      return regions;
    },
  },
  asyncComputed: {
    countries: {
      get() {
        return this.$api.getCountries(this.$store.getters.region)
          .then((response) => {
            const countries = [];
            if (response && response.content && response.content.length > 0) {
              Object.values(response.content).forEach((value) => {
                countries.push({
                  id: value.id,
                  code: value.code.toLowerCase(),
                  description: this.$t(`country.${value.code.toLowerCase()}`),
                });
              });
            }

            return countries.sort((a, b) => {
              if ((a.description || '') < (b.description || '')) {
                return -1;
              }
              return (b.description || '') > (a.description || '') ? 1 : 0;
            });
          });
      },
      watch: ['$store.getters.region'],
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>

.card-header[role=button] {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
}

.card-header.collapsed .icon-card {
  transform: rotate(90deg);
}

.card-header .icon-card {
  transition: .3s transform ease-in-out;
  font-size: 19px;
}
</style>
