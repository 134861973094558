<template>
  <div class="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex">
    <b-card class="app w-100"
            :class="{disabled : !isAdmin && !value}"
            :title="$t(`app.${titleapp}`)"
            :img-src="require(`@/assets/images/${app.image}`)"
            img-alt="App"
            img-top
            footer-class="text-center p-1">
      <b-card-text>
        <template v-for="(role) in userApp.roles">
          <CheckboxGroup
            v-if="isRoleActive(role) && role.roles.length"
            :disabled="readOnly"
            :key="role.id"
            :group="$t(`role.${role.name}`)"
            :groupedRoles="role.roles"
            :isAdmin="isAdmin"
            :loggedUserRole="loggedUserApp.roles.find(r => r.id === role.id)"
          />

          <b-form-checkbox
            :key="role.id"
            v-else-if="isRoleActive(role) && (isAdmin || loggedUserHasRole(role) || role.oldValue)"
            :value="true"
            :unchecked-value="false"
            :disabled="readOnly || !isAdmin && !loggedUserHasRole(role)"
            v-model="role.newValue"
          >
            {{ $t(`role.${role.name}`) }}
          </b-form-checkbox>

        </template>
      </b-card-text>
      <template v-if="!readOnly && (isAdmin || this.loggedUserApp.id)">
        <b-button
          v-if="hasModifications"
          variant="link" slot="footer"
          @click='saveApp'>
          <em class="material-icons notranslate">save_alt</em> {{
            $t('button.save')
          }}
        </b-button>
        <template v-else-if="app.configurationRequired && !hasModifications && hasAnyRole">
          <b-button
            v-if="app.configPath"
            variant="link" slot="footer"
            @click='configurationFunction(app)'>
            <em class="material-icons notranslate">settings</em> {{
              $t('button.config')
            }}
          </b-button>
          <b-button
            v-else
            variant="link" slot="footer"
            @click='showAlertConfig'>
            <em class="material-icons notranslate">info</em> {{
              $t('button.config')
            }}
          </b-button>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
  name: 'AppConfiguration',
  props: ['readOnly', 'userId', 'isUserCorporate', 'titleapp', 'app', 'region', 'configurationFunction', 'value'],
  components: {
    CheckboxGroup,
  },
  computed: {
    ...mapGetters(['isAdmin', 'isCorporate', 'user']),

    hasModifications() {
      return !!this.userApp.roles.find((role) => {
        if (role.roles && role.roles.length) {
          return role.roles.find((r) => r.oldValue !== r.newValue);
        }
        return role.oldValue !== role.newValue;
      });
    },
    hasAnyRole() {
      return !!this.userApp.roles.find((role) => {
        if (role.roles && role.roles.length) {
          return role.roles.find((r) => r.oldValue);
        }
        return role.oldValue;
      });
    },
  },
  data() {
    return {
      userApp: { roles: [] },
      loggedUserApp: {},
    };
  },
  mounted() {
    this.loggedUserApp = this.user.apps.find((uApp) => uApp.id === this.app.id) || { roles: [] };

    this.userApp = JSON.parse(JSON.stringify(this.app));

    this.initRoles(this.userApp.roles, this.value ? this.value.roles || [] : []);
  },

  watch: {
    value() {
      this.userApp = JSON.parse(JSON.stringify(this.app));

      this.initRoles(this.userApp.roles, this.value ? this.value.roles || [] : []);
    },
  },
  methods: {
    initRoles(roles, values) {
      roles.sort((a, b) => {
        if ((a.position || a.name).toString() < (b.position || b.name).toString()) {
          return -1;
        }
        return (b.position || b.name).toString() > (a.position || a.name).toString() ? 1 : 0;
      }).forEach((role) => {
        const userRole = values.find((value) => value.id === role.id);
        if (role.roles && role.roles.length) {
          this.initRoles(role.roles, userRole ? userRole.roles : []);
        } else {
          this.$set(role, 'oldValue', userRole !== undefined);
          this.$set(role, 'newValue', !!role.oldValue);
        }
      });
    },

    loggedUserHasRole(role) {
      return !!this.loggedUserApp.roles.find((r) => r.id === role.id);
    },

    showAlertConfig() {
      const textApp = `${this.region.toLowerCase()}.${this.titleapp.toLowerCase()}`;
      this.$swal({
        title: this.$t(`configApp.${textApp}.title`),
        html: this.isCorporate ? this.$t(`configApp.${textApp}.descCorporativo`) : this.$t(`configApp.${textApp}.descCliente`),
        type: 'info',
      });
    },

    rolesToSave(roles) {
      const response = [];
      roles.forEach((role) => {
        if (role.roles && role.roles.length) {
          response.push(...this.rolesToSave(role.roles));
        } else if (role.newValue) {
          response.push({ id: role.id });
        }
      });
      return response;
    },

    saveApp() {
      const addedRoles = this.rolesToSave(this.userApp.roles);

      const request = {
        apps: [{
          id: this.userApp.id,
          roles: addedRoles,
        }],
      };

      this.$api.updateUserRoles(this.userId, request)
        .then((res) => {
          this.updateAllRoles(this.userApp.roles);
          return res;
        });
    },

    updateAllRoles(roles) {
      roles.forEach((role) => {
        if (role.roles && role.roles.length) {
          this.updateAllRoles(role.roles);
        } else {
          role.oldValue = role.newValue;
        }
      });
    },

    isRoleActive(role) {
      return (this.isUserCorporate && role.activeCorporate)
        || (!this.isUserCorporate && role.activeClient);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.app {
  display: block;
}

.app .card-text {
  font-size: .85rem;
}

.card.app {
  &.disabled {
    opacity: .5;
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }

  img {
    padding: 1.5em;
    height: 120px;
    background: $prosegur-color;
  }

  .card-title {
    font-size: 1.4rem;
  }

  .card-body {
    margin-bottom: 47px;
  }

  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  ::v-deep .custom-control-input[disabled] ~ .custom-control-label,
  ::v-deep .custom-control-input:disabled ~ .custom-control-label {
    color: inherit;

    &::before {
      border-color: rgba(255, 210, 0, 0.3) !important;
      background-color: rgba(255, 210, 0, 0.3) !important;
    }
  }
}

</style>
