<template>
  <main class="d-flex" v-if="error && hasError">
    <div class="container d-flex flex-column">
      <div class="error-detail col-md-6 m-auto">
        <span class="error-icon">!</span>
        <div class="error-main">
          <div class="error-title" v-if="error.number !== 'unexpectedError.number'">
            {{ $t(error.title) }}
          </div>
          <div class="error-description">{{ $t(error.desc) }}</div>
          <br/>
          <p id="err-contact" class="error-contact">{{ $t(error.contact) }}</p>
          <router-link :to="{ path: currentPath }" v-if="type !== 'access_denied'">
            <span class="volver-caret">
              <span class="caret-left"/>
            </span>
            <span class="volver-error">{{ $t("errorCallback.tryAgain") }}</span>
          </router-link>
          <b-button variant="link" @click="silentLogout" v-else>
            <span class="volver-caret">
              <span class="caret-left"/>
            </span>
            <span class="volver-error">{{ $t("accessDenied.tryAgain") }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="js">

export default {
  data() {
    return {
      hasError: !!this.$route.query.error,
      type: this.$route.query.error,
      typeRedirect: this.$route.query.type || '',
      description: this.$route.query.error_description || '',
    };
  },

  async mounted() {
    if (!this.hasError) {
      await this.$auth.signinRedirectCallback()
        .then(() => {
          this.goToCurrentPath();
        })
        .catch(async (error) => {
          this.type = this.$route.query.error || '';
          this.description = error && error.error_description ? error.error_description : this.$route.query.error_description || '';
          this.hasError = true;
          // eslint-disable-next-line no-restricted-globals
          history.pushState({}, '', this.currentPath);
        });
    }
  },

  computed: {
    hasRedirect() {
      if (this.typeRedirect.startsWith('ACTIVATION')) {
        this.$auth.activateAccount();
        return true;
      }
      if (this.typeRedirect.startsWith('CHANGE_PWD')) {
        this.$auth.changePassword();
        return true;
      }
      if (this.typeRedirect.startsWith('RESET_MFA')) {
        this.$auth.resetMFA();
        return true;
      }
      if (this.description.startsWith('AADB2C90091')) {
        this.$auth.signIn();
        return true;
      }
      if (this.description.startsWith('AADB2C90088')) {
        this.$auth.signIn();
        return true;
      }
      return false;
    },
    error() {
      if (this.hasRedirect) {
        return null;
      }

      if (this.type === 'access_denied') {
        return {
          title: 'accessDenied.title',
          desc: 'accessDenied.description',
          contact: 'accessDenied.contact',
        };
      }
      return {
        title: 'errorCallback.title',
        desc: 'errorCallback.description',
        contact: 'errorCallback.contact',
      };
    },
    currentPath() {
      const currentLocation = localStorage.getItem('page.current');
      if (!currentLocation) {
        return '/';
      }
      const url = new URL(currentLocation);
      return `${url.pathname}${url.search}`;
    },
  },
  methods: {
    goToCurrentPath() {
      this.$router.push({
        path: this.currentPath,
      });
    },
    silentLogout() {
      const singOutPopup = window.open(
        this.$auth.getLogoutURI(),
        'logout',
        'width=300,height=400,menubar=no, toolbar=no, resizable=no, scrollbars=no',
      );

      setTimeout(() => {
        singOutPopup.close();

        this.goToCurrentPath();
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  height: 70vh;
  width: 100vw;
  display: flex;
  vertical-align: middle;
  text-align: center;
}

.error-icon {
  font-size: 50px;
  font-weight: bold;
  color: #efeded;
  background: #212529;
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin: auto;
}

.error-title {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  font-weight: 400;
}

.error-description {
  font-size: 1.1em;
  font-weight: 400;
}

.error-contact {
  padding-bottom: 1em;
}

.volver-caret {
  background-color: #ffd200;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: #22262e;
  font-size: 16px;
  display: inline-flex;
  vertical-align: top;

  .caret-left {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-right: 5px solid;
    display: inline-block;
    height: 0;
    width: 0;
    margin: auto auto auto 5px;
  }
}

.volver-error {
  display: inline-block;
  color: #22262e;
  font-size: 1.1em;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    text-decoration: underline;
  }
}
</style>
