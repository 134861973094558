<template>
  <main class="absolute-container">
    <ValidationObserver ref="observer" v-slot="{ validated, valid }">
      <b-form @submit.prevent="onSubmit" ref="observer">
        <Header :title="$t('page.newUser')">
          <b-row>
            <b-col xs="12" class="tabsTypeUser">
              <div class="w-100">
                <b-form-group class="mb-0">
                  <b-form-radio-group
                    id="radio-slots"
                    name="radio-options-slots"
                    buttons
                    button-variant="outline_secondary"
                    v-model="form.corporate"
                    @change="resetForm"
                  >
                    <b-form-radio :value="false">
                      {{ $t('user.clientUser') }}
                    </b-form-radio>
                    <b-form-radio :value="true"
                                  v-if="isAdmin || isCorporate">
                      {{ $t('user.prosegurUser') }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </Header>
        <div class="container d-flex flex-column pt-4">
          <div class="justify-content-center mb-5">
            <b-col xs="12" class="contentTabsTypeUser">
              <b-row>
                <b-col xs="12">
                  <b-form-group class="mb-0">
                    <b-form-checkbox class="custom-control-inline" v-model="form.manager"
                                     :value="true" :unchecked-value="false" switch>
                      {{ $t('user.manager') }}
                    </b-form-checkbox>
                    <b-form-checkbox class="custom-control-inline" v-model="form.admin"
                                     :value="true" :unchecked-value="false"
                                     v-if="form.corporate && isAdmin" switch>
                      {{ $t('user.admin') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr/>

              <b-row v-if="form.corporate">
                <b-col lg="4" md="6">
                  <ValidationProvider rules="required|prouser_exists"
                                      name="ipt_internalId"
                                      mode="lazy"
                                      v-slot="{ valid, errors, validate }">
                    <b-form-group :label="$t('user.internalId')" label-for="ipt_internalId">
                      <b-input-group>
                        <b-form-input
                          id="ipt_internalId"
                          name="ipt_internalId"
                          v-model="form.internalId"
                          :disabled="!form.corporate"
                          aria-describedby="aria-internalId"
                          :state="errors[0] ? false : (valid ? true : null)"
                          @input="() => form.internalId = form.internalId.toUpperCase()"
                        />
                        <b-input-group-append>
                          <b-button variant="outline-secondary"
                                    @click="validate()">
                            <b-icon icon="search" font-scale="0.9" shift-v="2"/>
                          </b-button>
                        </b-input-group-append>
                        <b-form-invalid-feedback id="aria-internalId">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <UserFields :edit="false"
                          :form="form"
                          :countries="countries"
                          :supervisor="supervisor"
                          :valid="valid"
                          :validated="validated" />
            </b-col>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </main>
</template>

<script lang="js">

import { mapGetters } from 'vuex';
import { ValidationObserver, extend } from 'vee-validate';
import Header from '@/components/Header.vue';
import UserFields from '@/components/UserFields.vue';

export default {
  components: {
    ValidationObserver,
    Header,
    UserFields,
  },
  data() {
    return {
      errorProuser: null,
      form: this.initialState(),
      supervisor: {
        id: this.$store.getters.isManager ? this.$store.getters.user.id : '',
        username: this.$store.getters.isManager ? this.$store.getters.user.username : '',
        email: this.$store.getters.isManager ? this.$store.getters.user.email : '',
      },
    };
  },

  beforeDestroy() {
    this.$store.dispatch('unlockRegion');
  },

  created() {
    extend('prouser_exists', {
      message: () => this.$t(`errorValidator.prouser_exists.${this.errorProuser}`, [this.$store.getters.region]),
      validate: (value) => this.$api.getProUser(value).then((res) => {
        if (res && res.active) {
          const nullToEmpty = (v) => v || '';

          this.form.username = nullToEmpty(res.username);
          this.form.name = nullToEmpty(res.name);
          this.form.surname = nullToEmpty(res.surname);
          this.form.email = this.buildEmail(res.username, res.email);
          this.form.nif = nullToEmpty(res.nif);
          this.form.phone = nullToEmpty(res.phone);
          this.form.countryCode = nullToEmpty(res.country.code.toLowerCase());
          this.form.department = nullToEmpty(res.department);

          if (!this.countries.find((country) => country.code === res.country.code.toLowerCase())) {
            this.errorProuser = 'regionNotMatch';
            return false;
          }
          this.errorProuser = null;
          return true;
        }

        this.errorProuser = 'notActive';
        return false;
      })
        .catch(() => {
          this.errorProuser = 'notFound';
          this.form.username = '';
          this.form.name = '';
          this.form.surname = '';
          this.form.email = '';
          this.form.department = '';
          this.form.phone = '';
          this.form.nif = '';
          this.form.countryCode = '';

          return false;
        }),
    });

    extend('user_exists', {
      validate: (value) => this.$api.checkUserExist(value),
      message: () => this.$t('errorValidator.user_exists', [this.form.region]),
    });
    extend('no_corporate_mail', {
      validate: (value) => (value ? !(value.includes('@prosegur.') || value.includes('@ext.prosegur.')) : true),
      message: () => this.$t('errorValidator.no_corporate_mail', [this.form.region]),
    });
  },
  mounted() {
    if (!this.$store.getters.isAdmin) {
      this.$store.dispatch('lockRegion');
      this.$store.dispatch('changeRegion', this.$store.getters.user.country.region.name);
    }
    this.$events.$on('region-change', (e) => this.onRegionChange(e));
  },
  watch: {
    'form.email': function updateUsername(newValue) {
      if (!this.form.corporate) {
        this.form.username = newValue;
      }
    },
  },
  methods: {

    buildEmail(username, email) {
      if (username) {
        const coporateEmail = '@prosegur.com';

        if (!email || !email.includes(coporateEmail)) {
          return username + coporateEmail;
        }

        return email;
      }
      return '';
    },

    async onSubmit() {
      this.$events.fire('onSubmit');

      await this.$nextTick();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$swal({
          title: this.$t('newUser.confirmTitle'),
          html: this.$t('newUser.confirmText'),
          type: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
        }).then((result) => {
          if (result.value) {
            this.createUser();
          }
        });
      });
    },

    createUser() {
      this.$api.createUser(this.form).then(async (response) => {
        this.$swal({
          title: this.$t('newUser.successTitle'),
          html: this.$t('newUser.successText'),
          type: 'success',
          confirmButtonText: this.$t('button.ok'),
        }).then(() => {
          this.$router.push({
            name: 'user',
            params: { id: response.id, user: response, showCreateAnotherUserButton: true },
          });
        });
      });
    },
    initialState() {
      return {
        admin: false,
        manager: false,
        username: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        countryCode: this.$store.getters.isAdmin ? '' : this.$store.getters.user.country.code.toLowerCase(),
        nif: '',
        department: '',
        corporate: this.$store.getters.isCorporate,
        internalId: '',
        genesisCode: this.$store.getters.isCorporate ? '' : this.$store.getters.user.genesisCode,
        clientCode: this.$store.getters.isCorporate ? '' : this.$store.getters.user.clientCode,
        clientDescription: this.$store.getters.isCorporate ? 'Prosegur' : this.$store.getters.user.clientDescription,
        supervisorId: this.$store.getters.isManager ? this.$store.getters.user.id : '',
      };
    },
    resetForm(corporate) {
      this.supervisor = {
        id: this.$store.getters.isManager ? this.$store.getters.user.id : '',
        username: this.$store.getters.isManager ? this.$store.getters.user.username : '',
        email: this.$store.getters.isManager ? this.$store.getters.user.email : '',
      };
      this.form = this.initialState();
      this.form.genesisCode = '';
      this.form.clientCode = '';
      this.form.clientDescription = corporate ? 'Prosegur' : '';
      this.form.corporate = corporate;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    onRegionChange() {
      this.form.countryCode = '';
    },
  },
  computed: {
    ...mapGetters(['region', 'isManager', 'isCorporate', 'isAdmin', 'user']),
  },
  asyncComputed: {
    countries: {
      get() {
        return this.$api.getCountries(this.$store.getters.region)
          .then((response) => {
            const countries = [];
            if (response && response.content && response.content.length > 0) {
              Object.values(response.content).forEach((value) => {
                countries.push({
                  id: value.id,
                  code: value.code.toLowerCase(),
                  description: this.$t(`country.${value.code.toLowerCase()}`),
                });
              });
            }

            return countries.sort((a, b) => {
              if ((a.description || '') < (b.description || '')) {
                return -1;
              }
              return (b.description || '') > (a.description || '') ? 1 : 0;
            });
          });
      },
      watch: ['$store.getters.region'],
      default: [],
    },
  },
};
</script>
