<template>
  <main class="absolute-container">
    <ValidationObserver ref="observer" v-slot="{ validated, valid }">
      <Header :title="$t('page.profile')"/>
      <div class="container d-flex flex-column pt-4">
        <div class="justify-content-center mb-5">
          <b-col xs="12" class="contentTabsTypeUser">
            <b-row>
              <b-col xs="12">
                <b-form-group class="mb-0">
                  <b-form-checkbox class="custom-control-inline"
                                   v-model="form.manager"
                                   disabled
                                   :value="true" :unchecked-value="false"
                                   v-if="form.manager" switch>
                    {{ $t('user.manager') }}
                  </b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline"
                                   v-model="form.admin"
                                   disabled
                                   :value="true" :unchecked-value="false"
                                   v-if="form.admin" switch>
                    {{ $t('user.admin') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <hr v-if="form.admin || form.manager"/>
            <UserFields :read-only="true"
                        :form="form"
                        :initialManager="initialManager"
                        :countries="countries"
                        :supervisor="supervisor"
                        :valid="valid"
                        :validated="validated"/>
          </b-col>

          <template v-for="(reg, index) in appsByRegion">
            <div class="card mb-2"
                 v-if="regionHasAvailableApps(reg)"
                 :key="index">
              <div class="card-header" v-b-toggle="'collapse-' + reg.name">
                <b-icon icon="chevron-down" class="float-right icon-card" font-scale="0.99"/>
                <span v-text="$t('user.apps') + ' - ' +  reg.name"/>
              </div>
              <b-collapse visible :id="'collapse-' + reg.name">
                <div class="card-body">
                  <div class="row">
                    <template v-for="(app) in reg.apps">
                      <AppConfiguration
                        :read-only="true"
                        :key="'a-'+app.name"
                        v-if="app.active"
                        :isUserCorporate="form.corporate"
                        :titleapp="app.name"
                        :app="app"
                        :value="apps.find(userApp => userApp.id === app.id) || {}"
                        :region="reg.name"
                      />
                    </template>
                  </div>
                </div>
              </b-collapse>
            </div>
          </template>
        </div>
      </div>
    </ValidationObserver>
  </main>
</template>

<script lang="js">

import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Header from '@/components/Header.vue';
import UserFields from '@/components/UserFields.vue';
import AppConfiguration from '@/components/AppConfiguration.vue';

export default {
  components: {
    ValidationObserver,
    Header,
    UserFields,
    AppConfiguration,
  },
  data() {
    return {
      form: {
        admin: false,
        manager: false,
        username: '',
        name: '',
        suername: '',
        email: '',
        phone: '',
        countryCode: '',
        nif: '',
        department: '',
        supervisorId: '',
        clientCode: '',
        clientDescription: '',
      },
      supervisor: {
        id: '',
        username: '',
        email: '',
      },
      initialManager: null,
      userId: '',
      apps: [],
    };
  },

  beforeDestroy() {
    this.$store.dispatch('unlockRegion');
  },

  mounted() {
    this.loadUserInfo(this.user);
    this.$store.dispatch('changeRegion', this.user.country.region.name);
    this.$store.dispatch('lockRegion');
  },

  methods: {
    loadUserInfo(user) {
      this.form.admin = user.admin;
      this.form.manager = user.manager;
      this.form.sub = user.sub;
      this.form.username = user.username;
      this.form.name = user.name;
      this.form.surname = user.surname;
      this.form.email = user.email;
      this.form.phone = user.phone;
      this.form.countryCode = user.country.code.toLowerCase();
      this.form.nif = user.nif;
      this.form.department = user.department;
      this.form.corporate = user.corporate;
      this.form.clientCode = user.clientCode;
      this.form.clientDescription = user.clientDescription;
      this.form.supervisorId = user.supervisor ? user.supervisor.id : '';

      if (user.supervisor) {
        this.form.supervisorId = user.supervisor.id;

        this.supervisor.id = user.supervisor.id;
        this.supervisor.email = user.supervisor.email;
        this.supervisor.username = user.supervisor.username;

        this.initialManager = {
          ...this.initialManager,
          id: this.supervisor.id,
          username: this.supervisor.username,
          email: this.supervisor.email,
        };
      }

      this.apps = user.apps || [];
    },

    regionHasAvailableApps(region) {
      return region.apps.filter((app) => app.active).length;
    },
  },
  computed: {
    ...mapGetters(['user', 'region']),

    appsByRegion() {
      const { regions } = this.$store.getters;
      this.$store.getters.regions.forEach((region) => {
        region.apps = this.user.apps.filter((app) => app.region.name === region.name);
      });

      return regions;
    },
  },
  asyncComputed: {
    countries: {
      get() {
        return this.$api.getCountries(this.$store.getters.region)
          .then((response) => {
            const countries = [];
            if (response && response.content && response.content.length > 0) {
              Object.values(response.content).forEach((value) => {
                countries.push({
                  id: value.id,
                  code: value.code.toLowerCase(),
                  description: this.$t(`country.${value.code.toLowerCase()}`),
                });
              });
            }

            return countries.sort((a, b) => {
              if ((a.description || '') < (b.description || '')) {
                return -1;
              }
              return (b.description || '') > (a.description || '') ? 1 : 0;
            });
          });
      },
      watch: ['$store.getters.region'],
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>

.card-header[role=button] {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
}

.card-header.collapsed .icon-card {
  transform: rotate(90deg);
}

.card-header .icon-card {
  transition: .3s transform ease-in-out;
  font-size: 19px;
}
</style>
