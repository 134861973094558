<template>
  <div class="header">
    <div class="mask">
      <div class="container">
        <slot name="title">
          <b-row class="pb-3" v-if="title">
            <b-col md="12">
              <p class="title"> {{ title }} </p>
            </b-col>
          </b-row>
        </slot>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['title'],
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .header {
    background-image: url($url-background);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 24%;
    color: white;

    .mask {
      padding-top: 130px;
      background-color: rgba(0, 0, 0, 0.6);

      .container{
        min-height: 118px;
      }
    }

    @media (max-width: 767px) {
      h4 {
        font-size: 1.3rem;
      }
      .mask {
        padding-top: 70px;

        .container{
          min-height: 0;
        }
      }
      .container-header, .title{
        padding-top: 20px;
      }
    }

    .title {
      font-size: 20px;
      font-weight: lighter;
    }

    @media (max-width: 991px) {
      & {
        background-position: center top;
      }
    }
  }
</style>
