<template>
  <div v-if="groupedRoles.length">
    <b-form-checkbox
      v-model="allSelected"
      :indeterminate="indeterminate"
      @change="toggleAll"
      :disabled="disabled || (!isAdmin && !groupedRoles.find(role => loggedUserHasRole(role)))"
      :key="selectedRoles.length"
      v-if="visibleRoles.length"
    >
      {{ group }}
    </b-form-checkbox>

    <div class="pl-3">
      <template v-for="(role) in groupedRoles">
        <b-form-checkbox :key="role.id"
                         v-if="isAdmin || role.oldValue || loggedUserHasRole(role)"
                         v-model="role.newValue"
                         :value="true"
                         :unchecked-value="false"
                         :disabled="disabled || (!isAdmin && !loggedUserHasRole(role))">
          {{ $t(`role.${role.name}`) }}
        </b-form-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxGroup',
  props: ['group', 'groupedRoles', 'loggedUserRole', 'isAdmin', 'disabled'],
  data() {
    return {
      allSelectedValue: false,
    };
  },
  methods: {
    toggleAll(checked) {
      Object.values(this.visibleRoles).forEach((role) => {
        if (this.isAdmin || this.loggedUserHasRole(role)) {
          role.newValue = checked;
        }
      });
    },
    loggedUserHasRole(role) {
      return !!(this.loggedUserRole && this.loggedUserRole.roles.find((r) => r.id === role.id));
    },
  },
  computed: {
    allSelected: {
      get() {
        return this.selectedRoles.length && this.selectedRoles.length === this.visibleRoles.length;
      },
      set(checked) {
        return checked;
      },
    },
    indeterminate() {
      return this.selectedRoles.length > 0
        && this.selectedRoles.length !== this.visibleRoles.length;
    },
    visibleRoles() {
      return this.groupedRoles
        .filter((role) => (this.isAdmin || role.oldValue || this.loggedUserHasRole(role)));
    },
    selectedRoles() {
      return this.visibleRoles.filter((role) => role.newValue === true);
    },
  },
};
</script>
