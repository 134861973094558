<template>
  <main class="filter-bar">
    <b-form @submit.prevent="doFilter" ref="observer">
      <b-row class="pb-2">
        <b-col xs="12">
          <b-form-group class="mb-0">
            <b-form-checkbox class="custom-control-inline" v-model="filters.active"
                             value="false" unchecked-value="" @change="doFilter" switch>
              {{ $t('user.showDisabled') }}
            </b-form-checkbox>
            <b-form-checkbox class="custom-control-inline" v-model="filters.admin"
                             value="true" unchecked-value="" @change="doFilter" switch
                             v-if="isAdmin">
              {{ $t('user.admin') }}
            </b-form-checkbox>
            <b-form-checkbox class="custom-control-inline" v-model="filters.manager"
                             value="true" unchecked-value="" @change="doFilter" switch>
              {{ $t('user.manager') }}
            </b-form-checkbox>
            <b-form-checkbox class="custom-control-inline" v-model="filters.corporate"
                             value="true" unchecked-value="" @change="doFilter" switch
                             v-if="isCorporate || isAdmin">
              {{ $t('user.showCorporate') }}
            </b-form-checkbox>

            <div class="float-right">
              <b-button-group class="table-controls">
                <b-button @click="onExport"
                          class="d-flex"
                          variant="light"
                          v-b-tooltip.hover
                          :title="$t('button.export')">
                  <img :alt="$t('button.export')" src="@/assets/images/export-icon.svg"/>
                </b-button>
                <b-dropdown
                  v-if="isCorporate"
                  variant="light"
                >
                  <template #button-content>
                    <b-icon icon="person-plus-fill" font-scale="0.99" class="m-auto"/>
                  </template>
                  <b-dropdown-item
                    :to="{name: 'newuser'}"
                    v-b-tooltip.hover
                    :title="$t('page.newUser')"
                  >
                    {{ $t('page.newUser') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="openModal"
                    v-b-tooltip.hover
                    :title="$t('page.bulkUser')"
                  >
                    {{ $t('page.bulkUser') }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-button :to="{name: 'newuser'}"
                          class="d-flex"
                          variant="light"
                          v-else
                          v-b-tooltip.hover
                          :title="$t('page.newUser')">
                  <b-icon icon="person-plus-fill" font-scale="0.99" class="m-auto"/>
                </b-button>
                <b-button @click="onRefresh"
                          class="d-flex"
                          variant="light"
                          v-b-tooltip.hover
                          :title="$t('global.refresh')">
                  <b-icon icon="arrow-clockwise" font-scale="0.99" class="m-auto"/>
                </b-button>
                <b-button v-b-toggle.collapse-filter-bar variant="light" class="d-flex">
                  <b-icon icon="filter" class="m-auto"/>
                  <span class="m-auto px-1">{{ $t('button.filters') }}</span>
                  <span v-if="filterLength > 0" class="m-auto">({{ filterLength }})</span>
                  <b-icon class="chevron m-auto" font-scale="0.75" icon="chevron-down"/>
                </b-button>
              </b-button-group>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-collapse id="collapse-filter-bar" ref="collapse-filter-bar">
        <hr/>

        <b-row>
          <b-col lg="4" md="6">
            <ValidationProvider rules="" name="ipt_userName" v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.username')" label-for="ipt_userName">
                <b-form-input
                  id="ipt_userName"
                  name="ipt_userName"
                  v-model="filters.username"
                  :state="errors[0] ? false : (valid ? true : null)"
                  autocomplete="off"
                  aria-describedby="aria-userName"
                />
                <b-form-invalid-feedback id="aria-userName">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="6">
            <ValidationProvider rules="alpha_spaces" name="ipt_firstName"
                                v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.firstName')" label-for="ipt_firstName">
                <b-form-input
                  id="ipt_firstName"
                  name="ipt_firstName"
                  v-model="filters.name"
                  :state="errors[0] ? false : (valid ? true : null)"
                  autocomplete="off"
                  aria-describedby="aria-firstName"
                  v-capitalize
                />
                <b-form-invalid-feedback id="aria-firstName">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="6">
            <ValidationProvider rules="alpha_spaces" name="ipt_lastName"
                                v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.lastName')" label-for="ipt_lastName">
                <b-form-input
                  id="ipt_lastName"
                  name="ipt_lastName"
                  v-model="filters.surname"
                  :state="errors[0] ? false : (valid ? true : null)"
                  autocomplete="off"
                  aria-describedby="aria-lastName"
                  v-capitalize
                />
                <b-form-invalid-feedback id="aria-lastName">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="4" md="6">
            <ValidationProvider rules="email" name="ipt_email"
                                v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.email')" label-for="ipt_email">
                <b-form-input
                  id="ipt_email"
                  name="ipt_email"
                  v-model="filters.email"
                  type="email"
                  :state="errors[0] ? false : (valid ? true : null)"
                  autocomplete="off"
                  aria-describedby="aria-email"
                />
                <b-form-invalid-feedback id="aria-email">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="4" md="6" v-if="isAdmin">
            <ValidationProvider rules="" name="select_country"
                                v-slot="{ errors }">
              <b-form-group :label="$t('user.country')" label-for="select_country">
                <KeyMultiselect
                  v-model="filters.country"
                  :options="countries"
                  track-by="code"
                  :searchable="true"
                  :preserve-search="false"
                  :show-labels="false"
                  name="select_country"
                  :disabled="!isAdmin"
                  :placeholder="$t('global.selectOption')"
                  autocomplete="off"
                  aria-describedby="aria-country"
                  :class="errors[0] ? 'is-invalid' : ''"
                  label="description"
                >
                  <template v-slot:placeholder>{{ $t("global.selectOption") }}</template>
                  <template v-slot:option="props">
                    <span :class="'flag-icon flag-icon-' + props.option.code"/>
                    <span v-text="$t('country.'+ props.option.code)"/>
                  </template>
                  <template v-slot:singleLabel="props">
                    <span :class="'flag-icon flag-icon-' + props.option.code"/>
                    <span v-text="$t('country.'+ props.option.code)"/>
                  </template>
                  <span slot="noResult">{{ $t('global.noResults') }}</span>
                </KeyMultiselect>
                <b-form-invalid-feedback id="aria-country">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="4" md="6" v-if="isCorporate">
            <ValidationProvider rules="" name="ipt_client"
                                v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.client')" label-for="ipt_client">
                <b-form-input
                  id="ipt_client"
                  name="ipt_client"
                  v-model="filters.client"
                  :disabled="!isCorporate && !isAdmin"
                  autocomplete="off"
                  aria-describedby="aria-client"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
                <b-form-invalid-feedback id="aria-client">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="4" md="6">
            <ValidationProvider rules="" name="ipt_managerId"
                                v-slot="{ valid, errors }">
              <b-form-group :label="$t('user.managerId')" label-for="ipt_managerId">
                <b-form-input
                  id="ipt_managerId"
                  name="ipt_managerId"
                  v-model="filters.supervisor"
                  type="text"
                  autocomplete="off"
                  aria-describedby="aria-managerId"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
                <b-form-invalid-feedback id="aria-managerId">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="4" md="6">
            <ValidationProvider rules="" name="select_apps"
                                v-slot="{ errors }">
              <b-form-group :label="$t('header.permissions')" label-for="select_apps">
                <KeyMultiselect
                  v-model="filters.roles"
                  :options="apps"
                  :multiple="true"
                  name="select_apps"
                  :placeholder="$t('global.selectOption')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :searchable="true"
                  :preserve-search="false"
                  :show-labels="false"
                  group-values="roles"
                  group-label="type"
                  track-by="key"
                  label="desc"
                  :limit="1"
                  :limit-text="limitText"
                  autocomplete="off"
                  aria-describedby="aria-apps"
                  :class="errors[0] ? 'is-invalid' : ''"
                >
                  <template v-slot:placeholder>{{ $t("global.selectOption") }}</template>
                  <span slot="noResult">{{ $t('global.noResults') }}</span>
                </KeyMultiselect>
                <b-form-invalid-feedback id="aria-apps">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="text-right d-md-block d-flex">
            <b-button
              variant="primary"
              class="mb-2"
              type="submit"
              v-b-toggle.collapse-filter-bar>
              <div class="d-flex">
                <b-icon icon="search" font-scale="0.8" class="m-auto"/>
                <span class="pl-1">{{ $t('button.search') }}</span>
              </div>
            </b-button>
            <b-button
              variant="secondary"
              class="mb-2 ml-2"
              @click="resetFilter">
              <div class="d-flex">
                <b-icon icon="x" font-scale="1.4" class="m-auto"/>
                <span>{{ $t('button.reset') }}</span>
              </div>
            </b-button>
          </b-col>
        </b-row>
      </b-collapse>
    </b-form>
    <bulk-user-modal />
  </main>
</template>

<script lang="js">
import { mapGetters } from 'vuex';
import BulkUserModal from '@/components/BulkUserModal.vue';
import KeyMultiselect from './KeyMultiselect.vue';

export default {
  components: {
    KeyMultiselect,
    BulkUserModal,
  },
  props: ['filters'],
  methods: {
    doFilter() {
      this.$events.fire('filter-set', this.filters);
    },
    onExport() {
      this.$events.fire('export', this.filters);
    },
    resetFilter() {
      this.$events.fire('filter-reset');
    },
    onRefresh() {
      this.$events.fire('refresh');
    },
    openModal() {
      this.$bvModal.show('bulkUserModal');
    },
    limitText(count) {
      return this.$t('global.moreOptions', [count]);
    },
    roleItem(role) {
      const roleName = this.$t(`role.${role.name}`);

      if (role.roles && role.roles.length) {
        return role.roles.map((subrole) => {
          const subRoleName = this.$t(`role.${subrole.name}`);
          return {
            key: subrole.id,
            desc: `${roleName} - ${subRoleName}`,
          };
        });
      }

      return {
        key: role.id,
        desc: this.$t(`role.${role.name}`),
      };
    },
    sort(a, b, sortby) {
      if ((a[sortby] || '') < (b[sortby] || '')) {
        return -1;
      }
      return (b[sortby] || '') > (a[sortby] || '') ? 1 : 0;
    },
  },
  computed: {
    ...mapGetters(['region', 'user', 'isCorporate', 'isAdmin', 'hasAnyRolAdmin']),
    filterLength() {
      let length = 0;
      Object.entries(this.filters).forEach(([key, value]) => {
        if (key !== 'region' && value) {
          length += 1;
        }
      });
      return length;
    },
    apps() {
      const apps = this.isCorporate ? (this.$store.getters.apps || []) : this.user.apps;
      const regions = this.$store.getters.regions || [];
      const options = [];

      regions.forEach((region) => {
        options.push(...apps.filter((app) => app.active && app.region.name === region.name)
          .map((app) => {
            const appName = this.$t(`app.${app.name}`);
            return {
              type: `${appName} - ${region.name}`,
              roles: app.roles.map((role) => this.roleItem(role))
                .flat()
                .sort((a, b) => this.sort(a, b, 'desc')),
            };
          }));
      });

      return options;
    },
  },
  asyncComputed: {
    countries: {
      get() {
        return this.$api.getCountries(this.filters.region)
          .then((response) => {
            const userCountry = this.user.country.code.toLowerCase();
            const countries = [];
            if (response && response.content && response.content.length > 0) {
              Object.values(response.content).forEach((value) => {
                countries.push({
                  id: value.id,
                  code: value.code.toLowerCase(),
                  description: this.$t(`country.${value.code.toLowerCase()}`),
                });
              });
            }

            if (this.filters.country && !countries.find((a) => a.code === this.filters.country)) {
              this.filters.country = this.isAdmin ? null : userCountry;
              // Lanzamos evento para forzar la busqueda con el pais correcto
              this.$events.fire('filter-set', this.filters);
            }

            return countries.sort((a, b) => this.sort(a, b, 'description'));
          });
      },
      watch: ['filters.region'],
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.filter-bar {
  padding-top: 10px;
}

.table-controls::v-deep .btn {
  border-color: rgba(93, 100, 107, 0.6);
}

.table-controls [aria-expanded=false] .chevron {
  transform: rotate(90deg);
}

.table-controls .chevron {
  transition: .3s transform ease-in-out;
}

.custom-control-inline.custom-control.custom-switch {
  margin-top: .5em;
  margin-bottom: .4em;
}

.commaSeparator:not(:first-child)::before {
  content: "\A";
  white-space: pre;
}

hr {
  border-color: $base-color;
}
</style>
