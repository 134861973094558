import Vue from 'vue';
import axios from 'axios';
import i18n from '../i18n';

const { CancelToken } = axios;

export default class ApiService {
  constructor(authService) {
    this.cancelSources = [];
    this.loader = null;
    this.countLoaders = 0;
    this.context = process.env.VUE_APP_SVC_BACKEND_BASE_URL;
    this.authService = authService;
    this.axios = axios.create({
      baseURL: `${this.context}/api`,
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    this.axios.interceptors.response.use((response) => response, async (error) => {
      if (error.response && error.response.headers['www-authenticate'] && await this.authService.getIdToken()) {
        await this.authService.signOut();
      } else if (typeof error.response === 'undefined' && !error.__CANCEL__) {
        Vue.swal({
          title: i18n.t('networkError.title'),
          html: `<ol class='text-left'>
                              <li>${i18n.t('networkError.cause1')}</li>
                              <li>${i18n.t('networkError.cause2')}</li>
                              <li>${i18n.t('networkError.cause3')}</li>
                             </ol>`,
          type: 'error',
        });
      }
      return Promise.reject(error);
    });
  }

  showLoading() {
    if (this.countLoaders === 0) {
      this.loader = Vue.$loading.show();
    }
    this.countLoaders += 1;
  }

  hideLoading() {
    this.countLoaders -= 1;
    if (this.countLoaders === 0) {
      this.loader.hide();
    }
  }

  processError(error) {
    if (error.response) {
      if (error.response.status === 400 && error.response.data.message) {
        let message;
        try {
          ({ message } = JSON.parse(error.response.data.message));
        } catch (e) {
          ({ message } = error.response.data);
        }

        Vue.swal({
          title: i18n.t('global.error'),
          html: message,
          type: 'error',
        });
      } else {
        Vue.swal({
          title: i18n.t('error500.title'),
          html: i18n.t('error500.description'),
          type: 'error',
        });
      }
    }

    return Promise.reject(error);
  }

  async defineHeaderAxios() {
    await Vue.prototype.$auth.getIdToken()
      .then((idToken) => {
        this.axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
      });
  }

  async getApplications() {
    await this.defineHeaderAxios();
    return this.axios.get('/applications')
      .then((response) => response.data);
  }

  async getRegions() {
    await this.defineHeaderAxios();
    return this.axios.get('/region')
      .then((response) => response.data);
  }

  async getCountries(region) {
    await this.defineHeaderAxios();

    return this.axios.get('/country', {
      params: {
        regionName: region,
        active: true,
      },
    })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async getLoggedUserDetail() {
    await this.defineHeaderAxios();
    return this.axios.get('/users/me')
      .then((response) => response.data);
  }

  async getUsers(filters, aux) {
    const { cancelSources } = this;

    this.getUsers.isCancellable = (a) => !!cancelSources[`/users${a || ''}`];

    this.getUsers.cancel = (a) => {
      if (this.getUsers.isCancellable(a)) {
        cancelSources[`/users${a || ''}`]();
      }
    };

    await this.defineHeaderAxios();

    return this.axios.get('/users', {
      params: filters,
      cancelToken: new CancelToken((c) => {
        cancelSources[`/users${aux || ''}`] = c;
      }),
    }).then((response) => {
      delete cancelSources[`/users${aux || ''}`];
      return response.data;
    });
  }

  async generateReportUsers(filters) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.get('/report/users', {
      responseType: 'blob',
      params: filters,
    }).then((response) => response)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }

  async getUserDetail(userId) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.get(`/users/${userId}`)
      .then((response) => response.data)
      .finally(() => {
        this.hideLoading();
      });
  }

  async checkUserExist(userId) {
    await this.defineHeaderAxios();

    return this.axios.get(`/users/available/${userId}`)
      .then((response) => (response && response.data)).catch(() => true);
  }

  async getProUser(internalId) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.get(`/api-global/${internalId}`)
      .then((response) => response.data)
      .finally(() => {
        this.hideLoading();
      });
  }

  async createUser(user) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.post('/users', user)
      .then((response) => response.data)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }

  async updateUser(user, id) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.put(`/users/${id}`, user)
      .then((response) => response.data)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }

  async updateUserRoles(userId, apps) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.put(`/users/${userId}/role`, apps)
      .then((response) => response.data)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }

  async changeStatusUser(userId, active) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.patch(`/users/${userId}/status`, { active })
      .then((response) => response.data)
      .finally(() => {
        this.hideLoading();
      });
  }

  async resendWelcomeEmail(userId) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.post(`/users/${userId}/resend-welcome-email`)
      .finally(() => {
        this.hideLoading();
      });
  }

  async getSolClient(countryCode, clientCode, sourceSystem) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.get(`/entities/${countryCode}/${clientCode}`, {
      params: { sourceSystem },
    })
      .then((response) => response.data)
      .finally(() => {
        this.hideLoading();
      });
  }

  async downloadBulkUserTemplate() {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.get('/bulk/users/template', {
      responseType: 'blob',
      headers: { 'Content-Type': 'blob' },
    })
      .then((response) => response.data)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }

  async bulkUserUpload(file) {
    this.showLoading();

    await this.defineHeaderAxios();

    return this.axios.post('/bulk/users', file, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => response.data)
      .catch((error) => this.processError(error))
      .finally(() => {
        this.hideLoading();
      });
  }
}
