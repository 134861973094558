<template>
  <div>
    <b-row>
      <b-col lg="4" md="6">
        <ValidationProvider
          :rules="!form.corporate ? 'required|alpha_spaces' : 'required'"
          name="ipt_firstName"
          v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.firstName')" label-for="ipt_firstName">
            <b-form-input
              id="ipt_firstName"
              name="ipt_firstName"
              v-model="form.name"
              :disabled="form.corporate || readOnly"
              autocomplete="off"
              aria-describedby="aria-firstName"
              :state="errors[0] ? false : (valid ? true : null)"
              v-capitalize
              trim
            />
            <b-form-invalid-feedback id="aria-firstName">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider
          :rules="!form.corporate ? 'required|alpha_spaces' : 'required'"
          name="ipt_lastName"
          v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.lastName')" label-for="ipt_lastName">
            <b-form-input
              id="ipt_lastName"
              name="ipt_lastName"
              v-model="form.surname"
              :disabled="form.corporate || readOnly"
              autocomplete="off"
              aria-describedby="aria-lastName"
              :state="errors[0] ? false : (valid ? true : null)"
              v-capitalize
              trim
            />
            <b-form-invalid-feedback id="aria-lastName">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider
          :rules="edit || readOnly ? 'required' : ('required|min:5|regex:' +
                  (form.corporate ?  regexInternal : regexExternal) +
                  '|user_exists')"
          mode="lazy"
          name="ipt_userName"
          v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.username')" label-for="ipt_userName">
            <b-form-input
              id="ipt_userName"
              name="ipt_userName"
              v-model="form.username"
              readonly
              disabled
              autocomplete="off"
              aria-describedby="aria-userName"
              :state="errors[0] ? false : (valid ? true : null)"
              trim
            />
            <b-form-invalid-feedback id="aria-userName">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider
          :rules="!edit && !form.corporate && !readOnly ?
          'required|email|no_corporate_mail' : 'required|email'"
          name="ipt_email"
          v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.email')" label-for="ipt_email">
            <b-form-input
              id="ipt_email"
              name="ipt_email"
              v-model="form.email"
              :disabled="edit || form.corporate || readOnly"
              type="email"
              autocomplete="off"
              aria-describedby="aria-email"
              :state="errors[0] ? false : (valid ? true : null)"
              v-lowercase
              trim
            />
            <b-form-invalid-feedback id="aria-email">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider rules="" name="ipt_nif"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.nif')" label-for="ipt_nif">
            <b-form-input
              id="ipt_nif"
              name="ipt_nif"
              v-model="form.nif"
              :disabled="form.corporate || readOnly"
              autocomplete="off"
              aria-describedby="aria-nif"
              :state="errors[0] ? false : (valid ? true : null)"
              trim
            />
            <b-form-invalid-feedback id="aria-nif">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider :rules="form.corporate ? '' : 'phone'"
                            name="ipt_phoneNumber"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.phoneNumber')" label-for="ipt_phoneNumber">
            <b-form-input
              id="ipt_phoneNumber"
              name="ipt_phoneNumber"
              v-model="form.phone"
              :disabled="form.corporate || readOnly"
              type="tel"
              autocomplete="off"
              aria-describedby="aria-phoneNumber"
              :state="errors[0] ? false : (valid ? true : null)"
              trim
            />
            <b-form-invalid-feedback id="aria-phoneNumber">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>

    <hr/>

    <b-row>
      <b-col lg="4" md="6">
        <ValidationProvider rules="required" name="select_country"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.country')" label-for="select_country">
            <KeyMultiselect
              v-model="form.countryCode"
              :options="countries"
              track-by="code"
              :class="errors[0] ? 'is-invalid' : ''"
              :searchable="true"
              :preserve-search="false"
              :disabled="readOnly || !isAdmin || form.corporate"
              :show-labels="false"
              name="select_country"
              :placeholder="$t('global.selectOption')"
              autocomplete="off"
              aria-describedby="aria-country"
              label="description"
              :state="errors[0] ? false : (valid ? true : null)"
              @input="onChangeCountry"
            >
              <template v-slot:placeholder>{{ $t("global.selectOption") }}</template>
              <template v-slot:option="props">
                <span :class="'flag-icon flag-icon-' + props.option.code"/>
                <span v-text="$t('country.'+ props.option.code)"/>
              </template>
              <template v-slot:singleLabel="props">
                <span :class="'flag-icon flag-icon-' + props.option.code"/>
                <span v-text="$t('country.'+ props.option.code)"/>
              </template>
              <span slot="noResult">{{ $t('global.noResults') }}</span>
            </KeyMultiselect>
            <b-form-invalid-feedback id="aria-country">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider rules="" name="ipt_department"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.department')" label-for="ipt_department">
            <b-form-input
              id="ipt_department"
              name="ipt_department"
              v-model="form.department"
              :disabled="form.corporate || readOnly"
              autocomplete="off"
              aria-describedby="aria-department"
              :state="errors[0] ? false : (valid ? true : null)"
              trim
            />
            <b-form-invalid-feedback id="aria-department">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider :rules="form.corporate ? '' : 'required'"
                            name="ipt_client"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.client')" label-for="ipt_client">
            <b-input-group v-if="!(!isAdmin && !isCorporate || readOnly) && solClient">
              <b-form-input
                id="ipt_client"
                name="ipt_client"
                v-model.lazy="form.clientDescription"
                :disabled="true"
                autocomplete="off"
                aria-describedby="aria-client"
                @change="onChangeClient"
                :state="errors[0] ? false : (valid ? true : null)"
              />
              <b-input-group-append>
                <b-button
                  id="btn_find_client"
                  name="btn_find_client"
                  variant="outline-secondary"
                  :disabled="!form.countryCode"
                  @click="openFindClient">
                  <b-icon icon="search" font-scale="0.9" shift-v="2"/>
                </b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="aria-client">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-input-group>
            <template v-else>
              <b-form-input
                id="ipt_client"
                name="ipt_client"
                v-model.lazy="form.clientDescription"
                :disabled="!isAdmin && !isCorporate || readOnly"
                autocomplete="off"
                aria-describedby="aria-client"
                @change="onChangeClient"
                :state="errors[0] ? false : (valid ? true : null)"
              />
              <b-form-invalid-feedback id="aria-client">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </template>
            <!-- si es true es campo de texto, si es false se abre ventana de consulta -->
            <b-form-checkbox class="custom-control-inline"
                             v-model="solClient"
                             :value="false"
                             :unchecked-value="true"
                             v-if="!(!isAdmin && !isCorporate || readOnly)"
                             @change="onChangeFindClient">
              {{ $t('user.checkFindClient') }}
            </b-form-checkbox>
            <find-client-modal :form="form" @change="onChangeClient"/>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>

    <hr/>

    <b-row>
      <b-col lg="4" md="6">
        <ValidationProvider :rules="!form.email ? 'required' : ''"
                            name="select_managerId"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.managerId')" label-for="select_managerId">
            <KeyMultiselect
              id="select_managerId"
              v-model="supervisor.username"
              :options="managers"
              track-by="username"
              label="username"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="20"
              :show-no-results="false"
              @search-change="findManagers"
              @fullValueChange="onChangeManager"
              @close="checkSelected"
              :show-labels="false"
              :class="(errors[0] ? 'is-invalid' : '') +
                          (managers.length === 0 && !isLoading ? ' emptySelect' :'')"
              :preserve-search="false"
              :disabled="readOnly || !form.countryCode ||
              (!form.clientDescription && !form.corporate)"
              name="select_managerId"
              :placeholder="$t('global.selectOptionOrTyping')"
              autocomplete="off"
              aria-describedby="aria-managerId"
              :state="errors[0] ? false : (valid ? true : null)"
            >
              <template v-slot:singleLabel="props">
                <span v-text="props.option.username"/>
              </template>

            </KeyMultiselect>

            <b-form-invalid-feedback id="aria-managerId">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="4" md="6">
        <ValidationProvider :rules="!form.email ? 'required|email' : 'email'"
                            name="ipt_emailManager"
                            v-slot="{ valid, errors }">
          <b-form-group :label="$t('user.emailManager')" label-for="ipt_emailManager">
            <b-form-input
              id="ipt_emailManager"
              name="ipt_emailManager"
              v-model="supervisor.email"
              type="email"
              disabled
              autocomplete="off"
              aria-describedby="aria-emailManager"
              :state="errors[0] ? false : (valid ? true : null)"
            />
            <b-form-invalid-feedback id="aria-emailManager">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>

    <b-row v-if="!readOnly">
      <b-col md="12" class="text-right">
        <b-button type="submit" id="saveForm" variant="primary"
                  :disabled="validated && !valid || this.isLoading">
          <em class="material-icons notranslate">save_alt</em> {{ $t('button.save') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FindClientModal from '@/components/FindClientModal.vue';
import KeyMultiselect from '@/components/KeyMultiselect.vue';

export default {
  name: 'UserFields',
  components: {
    FindClientModal,
    KeyMultiselect,
  },
  props: ['readOnly', 'edit', 'form', 'supervisor', 'countries', 'validated', 'valid', 'initialManager'],
  data() {
    return {
      regexInternal: '(?!^\\d+$)^.+$',
      /**
       * Validacion username externos
       * ============================
       * No puede tener formato de ID de interno EJ: ES12345678, ESX1234567, ESB1234567
       * No pueden ser solo números
       * Admite letras minusculas, mayusculas, numeros y los caracteres especiales @._+-&
       * No puede empezar por ninguno de los caracteres especiales
       * No puede haber 2 de los caracteres especiales seguidos
       * No puede terminar por los caracteres especiales
       */
      regexExternal: '^(?![a-zA-Z]{2}[xXbB\\d][\\d]{7}$)(?!^\\d+$)(?![@\'&._+-])(?!.*[@\'&._+-]{2})[a-zA-Z0-9@\'&._+-]+([^@\'&._+-])$',
      isLoading: false,
      managers: [],
      solClient: true,
    };
  },
  mounted() {
    if (this.supervisor) {
      this.managers.push(this.supervisor);
    }

    this.solClient = this.form.clientCode || !this.form.clientDescription;

    this.$events.on('onSubmit', () => this.checkSelected());
  },
  computed: {
    ...mapGetters(['region', 'isManager', 'isCorporate', 'isAdmin', 'user']),
  },
  watch: {
    'form.corporate': {
      immediate: true,
      handler(corporate) {
        this.solClient = !corporate;
      },
    },
  },
  methods: {
    onChangeFindClient() {
      this.form.clientCode = null;
      this.form.clientDescription = null;
    },
    openFindClient() {
      this.$nextTick(() => this.$bvModal.show('findClientByCountry'));
    },
    onChangeCountry(newCountry) {
      this.form.clientCode = null;
      this.form.clientDescription = null;
      if (newCountry && this.form.corporate) {
        this.findManagers();
      }
    },
    onChangeClient(newClient) {
      if (!this.form.corporate && newClient && this.form.countryCode) {
        this.findManagers();
      }
    },
    onChangeManager(newManager) {
      this.form.supervisorId = newManager ? newManager.id : '';
      this.supervisor.id = newManager ? newManager.id : '';
      this.supervisor.username = newManager ? newManager.username : '';
      this.supervisor.email = newManager ? newManager.email : '';
    },
    checkSelected() {
      if (!this.managers.find((e) => e.username === this.supervisor.username)) {
        this.form.supervisorId = '';
        this.supervisor.id = '';
        this.supervisor.username = '';
        this.supervisor.email = '';
      }
    },

    cancelPreviousRequest() {
      if (this.$api.getUsers.isCancellable) {
        if (this.$api.getUsers.isCancellable('client')) {
          this.$api.getUsers.cancel('client');
        }
        if (this.$api.getUsers.isCancellable('corporate')) {
          this.$api.getUsers.cancel('corporate');
        }
        if (this.$api.getUsers.isCancellable('selectedClient')) {
          this.$api.getUsers.cancel('selectedClient');
        }
        if (this.$api.getUsers.isCancellable('selectedCorporate')) {
          this.$api.getUsers.cancel('selectedCorporate');
        }
      }
    },

    reduceManagers(managers) {
      const reduced = [];
      const keysReduced = [];

      Object.values(managers).forEach((manager) => {
        if (manager.username
          && keysReduced.indexOf(manager.username) === -1) {
          keysReduced.push(manager.username);
          reduced.push(manager);
        }
      });

      return reduced;
    },

    findManagers(query) {
      if (this.form.countryCode && (this.form.corporate || this.form.clientDescription)) {
        // Cancelamos las llamadas anteriores
        this.cancelPreviousRequest();

        this.isLoading = true;

        const filters = {
          size: 10,
          page: 0,
          username: query || null,
          region: this.$store.getters.region,
          country: this.form.countryCode,
          manager: true,
          orderBy: 'username',
          direction: 'DESC',
        };

        // Filtro para busqueda de clientes
        const filtersClient = JSON.parse(JSON.stringify(filters));
        filtersClient.client = this.form.clientCode || this.form.clientDescription;
        filtersClient.corporate = false;

        // Filtro para busqueda de corporativos
        const filtersCorp = JSON.parse(JSON.stringify(filters));
        filtersCorp.corporate = true;

        const promises = this.getManagerPromises(query, filtersClient, filtersCorp);

        Promise.all(promises).then((responses) => {
          const managers = [];
          if (this.initialManager) {
            managers.push(this.initialManager);
          }

          Object.values(responses).forEach((response) => {
            managers.push(...response.content);
          });

          // Al realizar varias llamadas, hay posibilidad de tener repetidos asi que los filtramos
          this.managers = this.reduceManagers(managers);

          this.isLoading = false;
        })
          .catch((error) => {
            this.managers = [];
            return error;
          });
      }
    },

    getManagerPromises(query, filtersClient, filtersCorp) {
      const promises = [];

      if (this.form.corporate) {
        promises.push(this.$api.getUsers(filtersCorp, 'corporate'));
      } else if (this.isCorporate) {
        promises.push(this.$api.getUsers(filtersClient, 'client'));
        promises.push(this.$api.getUsers(filtersCorp, 'corporate'));
      } else {
        promises.push(this.$api.getUsers(filtersClient, 'client'));
      }

      // Si no hay filtro en el campo, pero si hay manager seleccionado
      if (!query && this.supervisor.username) {
        // Hacemos una busqueda para obtener dicho manager si o si
        // Riesgo de duplicados
        if (!this.form.corporate) {
          const filterSelectedClient = JSON.parse(JSON.stringify(filtersClient));
          filterSelectedClient.username = this.supervisor.username;
          promises.push(this.$api.getUsers(filterSelectedClient, 'selectedClient'));
        }
        if (this.isCorporate) {
          const filterSelectedCorp = JSON.parse(JSON.stringify(filtersCorp));
          filterSelectedCorp.username = this.supervisor.username;
          promises.push(this.$api.getUsers(filterSelectedCorp, 'selectedCorp'));
        }
      }

      return promises;
    },
  },
};
</script>
