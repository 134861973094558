<template>
  <div>
    <b-modal
      :id="modalId"
      :content-class="classModalContent"
      :hide-footer="hideFooter"
      :title="title"
      :size="size"
      :no-enforce-focus="true"
      header-class="p-modal-header"
      modal-class="p-modal"
      @show="(e) => $emit('show', e)"
      @shown="(e) => $emit('shown', e)"
      @change="(e) => $emit('change', e)"
      @close="(e) => $emit('close', e)"
      @hide="(e) => $emit('hide', e)"
      @hidden="(e) => $emit('hidden', e)"
    >
      <template #modal-header="{ close }">
        <span>
          <span class="p-header-icon">
            <slot name="header-icon"></slot>
          </span>
          <span>
            <span class="p-modal-title-text">{{ title }}</span>
          </span>
        </span>
        <span>
          <span @click="close()"><b-icon-x class="close-icon"/></span>
        </span>
      </template>
      <slot name="modal-content"></slot>
      <template #modal-footer>
        <slot name="modal-footer"></slot>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    classModalContent: {
      type: String,
      required: false,
      default: 'p-modal-content',
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.p-header-icon {
  padding-right: 1rem;
}

::v-deep .p-modal-header {
  height: 51px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: $prosegur-color;
}

.p-modal-title-text {
  font-weight: 500;
  color: $dark-color;
  text-transform: uppercase;
}

.close-icon {
  font-size: 2rem;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  cursor: pointer;
}
</style>
