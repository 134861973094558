<template>
  <div>
    <template>
      <div id="app">
        <NavBar v-if="signedIn"/>
        <router-view/>
      </div>
      <CookieBanner v-if="signedIn"/>
      <session-expired-modal v-if="signedIn"/>
    </template>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import CookieBanner from '@/components/CookieBanner.vue';
import SessionExpiredModal from '@/components/SessionExpiredModal.vue';

export default {
  name: 'App',
  components: {
    SessionExpiredModal,
    NavBar,
    CookieBanner,
  },
  computed: {
    signedIn() {
      return this.$store.getters.isSignedIn;
    },
  },
};
</script>
