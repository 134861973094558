<template>
  <multiselect
    v-bind="$attrs"
    v-on="listeners"
    :value="completeValue"
    :options="options"
    :track-by="trackBy"
    :taggable="taggable"
    :group-values="groupValues"
    @tag="addTag"
    class="key-multiselect"
  >
    <template slot="noOptions">{{ $t("global.noOptions") }}</template>
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
// See discussion on this issue:
// https://github.com/shentao/vue-multiselect/issues/385
export default {
  name: 'KeyMultiselect',
  inheritAttrs: false,
  components: {
    Multiselect,
  },
  props: {
    value: [Number, String, Array],
    options: Array,
    trackBy: String,
    groupValues: String,
    taggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    completeValue: {
      get() {
        if (!this.value) return null;
        if (this.$attrs.multiple) {
          // TODO: handle value not found if taggable
          return this.value.map((value) => this.findOption(value)).filter((value) => value);
        }
        const completeValue = this.findOption(this.value);
        if (completeValue === undefined && this.taggable) {
          this.addTag(this.value);
        }
        return completeValue;
      },
      set(v) {
        this.$emit('input', this.$attrs.multiple
          ? v.map((value) => value[this.trackBy])
          : (v && v[this.trackBy]));
      },
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onChange,
      };
    },
  },
  watch: {
    completeValue(value) {
      this.$emit('fullValueChange', value);
    },
  },
  methods: {
    onChange(value) {
      this.completeValue = value;
    },
    findOption(value) {
      if (this.groupValues) {
        let found = null;
        Object.values(this.options).forEach((option) => {
          found = option[this.groupValues].find((obj) => obj[this.trackBy] === value) || found;
        });

        return found;
      }

      return this.options.find((option) => option[this.trackBy] === value);
    },
    addTag(value) {
      const newOption = {
        [this.trackBy]: value,
        [this.$attrs.label]: value,
      };
      this.options.push(newOption);
      // TODO: if multiple then push
      this.completeValue = newOption;
    },
  },
};
</script>
<style lang="scss" scoped>
  .key-multiselect {
    .multiselect__option, .multiselect__single {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .multiselect--disabled {
    background: none;
  }

</style>
