import 'babel-polyfill';
import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import AsyncComputed from 'vue-async-computed';
import VueEvents from 'vue-events';
import VueLoading from 'vue-loading-overlay';
import VueSweetalert2 from 'vue-sweetalert2';
import ApiService from '@/services/api-service';
import AuthService from '@/services/auth-service';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import './vee-validate';

import '@/assets/scss/_bootstrap-vue.scss';

import 'material-icons/iconfont/material-icons.scss';
import 'flag-icon-css/sass/flag-icon.scss';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import '@/assets/scss/style.scss';
import './registerServiceWorker';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(AsyncComputed);
Vue.use(VueEvents);
Vue.use(VueSweetalert2);

Vue.use(VueLoading, {
  color: '#ffd200',
  backgroundColor: 'rgba(0,0,0,.4)',
  width: 80,
  height: 80,
  loader: 'dots',
  zIndex: 99999,
});
Vue.component('loading', VueLoading);

Vue.directive('capitalize', {
  update(el) {
    const event = new Event('input', { bubbles: true });
    el.value = el.value ? el.value.toString().charAt(0).toUpperCase() + el.value.slice(1) : '';
    el.dispatchEvent(event);
  },
});

Vue.directive('lowercase', {
  update(el) {
    const event = new Event('input', { bubbles: true });
    el.value = el.value ? el.value.toLowerCase() : '';
    el.dispatchEvent(event);
  },
});

Vue.prototype.$auth = new AuthService();
Vue.prototype.$api = new ApiService(Vue.prototype.$auth);

const vueRouter = router();

new Vue({
  router: vueRouter,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    this.$events.$on('language-change', (e) => this.onLanguageChange(e));
  },
  methods: {
    onLanguageChange(newLanguage) {
      i18n.locale = newLanguage;
      const html = document.documentElement; // returns the html tag
      html.setAttribute('lang', newLanguage);
    },
  },
}).$mount('#app');
