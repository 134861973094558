import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export default class AuthService {
  constructor() {
    this.policyLogin = process.env.VUE_APP_SSO_POLICY_LOGIN;

    this.policyActivateAccount = process.env.VUE_APP_SSO_POLICY_ACTIVATE_ACCOUNT;

    this.policyChangePassword = process.env.VUE_APP_SSO_POLICY_CHANGE_PASSWORD;

    this.policyResetMFA = process.env.VUE_APP_SSO_POLICY_EDIT_QR;

    const settings = {
      response_mode: 'query',
      authority: `${process.env.VUE_APP_SSO_AUTHORITY}`,
      client_id: `${process.env.VUE_APP_SSO_CLIENT_ID}`,
      redirect_uri: `${window.origin}/callback`,
      automaticSilentRenew: true,
      response_type: 'code',
      scope: `openid ${process.env.VUE_APP_SSO_CLIENT_ID}`,
      post_logout_redirect_uri: `${window.origin}`,
      filterProtocolClaims: true,
      loadUserInfo: false,
    };

    localStorage.setItem('oidc.settings', JSON.stringify(settings));
    settings.userStore = new WebStorageStateStore({ store: window.localStorage });

    this.userManager = new UserManager(settings);
  }

  getUser() {
    return this.userManager.getUser()
      .then((user) => {
        if (user && user.expired) {
          return this.userManager.signinSilent()
            .catch((error) => {
              console.log(error);

              this.signIn();
            });
        }

        return user;
      })
      .catch((error) => console.log(error));
  }

  getSignedIn() {
    return this.getUser()
      .then((user) => {
        if (user == null) {
          return this.signIn()
            .then((refreshedUser) => refreshedUser != null)
            .catch((error) => {
              console.log(error);
              return false;
            });
        }

        return true;
      })
      .catch((error) => console.log(error));
  }

  signIn() {
    return this.userManager.signinRedirect()
      .catch((error) => console.log(error));
  }

  signOut() {
    return this.userManager.signoutRedirect()
      .catch((error) => console.log(error));
  }

  getLogoutURI() {
    return this.userManager.metadataService.getMetadata().then((metadata) => `${metadata.end_session_endpoint}`)
      .catch((error) => console.log(error));
  }

  getIdToken() {
    return this.getUser().then((data) => data.id_token)
      .catch((error) => console.log(error));
  }

  findRole(role) {
    return this.getUser()
      .then((user) => user.groups)
      .then((groups) => groups.find((element) => element === role));
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  }

  async activateAccount() {
    await this.b2CAction(this.policyActivateAccount);
  }

  async changePassword() {
    await this.b2CAction(this.policyChangePassword);
  }

  async resetMFA() {
    await this.b2CAction(this.policyResetMFA);
  }

  async b2CAction(policy) {
    this.userManager.metadataService.getMetadata().then((d) => {
      d.authorization_endpoint = d.authorization_endpoint
        .replaceAll(this.policyLogin, policy);
      this.signIn();
    })
      .catch((error) => console.log(error));
  }
}
