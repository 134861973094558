<template>
  <transition name="slide-fade">
    <div id="cookies" v-if="!accepted">
      <div class="container">
        <b-row>
          <b-col>
            <div class="d-flex">
              <img src="@/assets/images/logoprosegurBlanco.svg"
                   class="mr-3 mb-auto" alt="Prosegur Cash"
                   width="30" height="30"/>
              <div>
                <p v-html="$t('global.cookiesPolicy')"/>
                <b-button pill variant="outline-primary" @click="acceptCookies">
                  {{ $t('button.acceptCookies') }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookieBanner',
  data() {
    return {
      accepted: localStorage.getItem('acceptCookies') === 'true',
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('acceptCookies', 'true');
      this.accepted = true;
    },
  },
};
</script>

<style scoped>
  #cookies {
    position: fixed;
    background-color: #211f1f;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 1rem 0;
    z-index: 999;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(200px);
    opacity: 0;
  }
</style>
